"use client";
import { Image, Space } from "antd";
import React from "react";
import { Repo } from "../data/Repos";

const RepoCard: React.FC<{ repo: Repo }> = ({ repo }) => {
    const buildSubtitle = () => {
        if (repo.is_demo) {
            return <div className="repo-card-demo-badge">DEMO</div>;
        }

        return (
            <div className="repo-card-org" title={repo.org}>
                {repo.org}
            </div>
        );
    };

    return (
        <div className="repo-card">
            <Space align="center">
                <Image
                    width={36}
                    height={36}
                    className="repo-icon"
                    alt={`${repo.full_name} icon`}
                    preview={false}
                    src={repo.icon}
                />
                <div className="repo-card-text-container">
                    <div className="repo-card-title">{repo.name}</div>
                    {buildSubtitle()}
                </div>
            </Space>
        </div>
    );
};

export default RepoCard;
