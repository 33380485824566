import { TurnEventBase } from "./SolverInterfaceEvent";

export interface SolverLogContent {
    message: string;
    action_payload: ActionPayload;
}

export interface ActionPayload {
    anchor_start: number | undefined;
    anchor_end: number | undefined;
    actions: string[] | undefined;
}

export enum WorkspaceCreationProgress {
    DONE = "done",
    IN_PROGRESS = "in_progress",
    ERROR = "error",
}

export interface WorkspaceCreationProgressContent {
    status: WorkspaceCreationProgress;
    percent_done: number | undefined;
}

export interface AgentThoughtContent {
    message: string;
}

export interface PlanContent {
    mermaid: string;
}

export interface RemoteCommitsContent {
    commits: {
        commit: string;
        author: string;
        date: string;
        message: string;
        patch: string;
    }[];
}

export interface DocumentationContent {
    summary: string;
}

export interface CodeCoverageContent {
    command: string;
    message: string;
    file: string;
    coverage: number;
    error_code:
        | "paths_not_found"
        | "path_to_run_not_found"
        | "path_to_measure_not_found"
        | "coverage_run_failed"
        | "report_generation_failed"
        | undefined;
    error_output: string | undefined;
    definitions: {
        definition: string;
        line_start: number;
        line_end: number;
        coverage: number;
    }[];
}

export interface ChangesContent {
    start: string;
    end: string;
}

export interface ProfileContent {
    message: string;
    error: string | undefined;
    svg: string | undefined;
}

export interface SolutionReviewContent {
    task_summary: string;
    task_suggestions: string[];
    impl_summary: string;
    review_summary: string;
    review_issues: string[];
    guidelines_summary: string;
    guidelines_suggestions: string[];
    error: boolean;

    init: boolean;
    init_message: string;
}

export interface RelevantFile {
    path: string;
    score: number;
    relevance: string;
    excerpts: string[];
}

export interface RelevantFilesContent {
    pending_message: string | undefined;
    files: RelevantFile[];
}
export interface BaseLinterError {
    filename: string;
    line: number;
    message: string;
}

export interface LinterError extends BaseLinterError {
    linter_type: "mypy" | "ruff" | "eslint" | "typescript";
}

export interface BanditError extends BaseLinterError {
    linter_type: "bandit";
    severity: string;
    issue_cwe_id: number;
    issue_cwe_link: string;
}

export type AnyLinterError = LinterError | BanditError;

export interface LinterContent {
    empty_message: string;
    errors: AnyLinterError[];
}

export interface BlameContent {
    message: string;
    error: string | undefined;
    path: string | undefined;
    definition: string | undefined;
    error_type: "path_not_found" | "definition_not_found" | "no_changes_found" | undefined;
    line_start: number | undefined;
    line_end: number | undefined;
    commits: {
        commit: string;
        author: string;
        date: string;
        message: string;
        patch: string;
    }[];
}

export type BisectRevisionState = "good" | "bad" | "untested" | "pruned";
export interface BisectContent {
    state: "started" | "running" | "finished";
    target: string;
    depth: number;
    last_tested_rev: string | undefined;
    test_revisions: {
        [revision: string]: {
            state: BisectRevisionState;
            message: string;
        };
    };
    breaking_commit: {
        commit: string;
        author: string;
        date: string;
        message: string;
        patch: string;
    };
    error_type:
        | "target_not_found"
        | "target_not_python"
        | "target_not_failing_now"
        | "target_not_passing_before"
        | undefined;
    error_message: string | undefined;
}

export interface ExecutionContent {
    command: string;
    exit_code: number;
    stdout: string;
    stderr: string | undefined;
    completed: boolean;
    error_msg: string | undefined;
}

export interface UnknownTurnEventContent {}

export interface PlanEvent extends TurnEventBase {
    content: PlanContent;
}
export interface OpenFileContent {
    path: string;
    status: "success" | "file_not_found" | "directory" | "definition_not_found";
}

export interface TextSearchContent {
    pattern: string | null;
    path: string | null;
}
export interface ProjectTreeContent {
    path: string;
}

export interface SolvingStoppedContent {
    solving_error: string | undefined;
}
