"use client";
import React from "react";

import { Progress, Typography } from "antd";

import { WorkspaceCreationProgress, WorkspaceCreationProgressContent } from "../../data/TurnEventContent";

import "./Bisect.css";

interface WorkspaceCreationProgressProps {
    workspaceCreationProgressContent: WorkspaceCreationProgressContent;
}

const WorkspaceProgress: React.FC<WorkspaceCreationProgressProps> = ({ workspaceCreationProgressContent }) => {
    switch (workspaceCreationProgressContent.status) {
        case WorkspaceCreationProgress.DONE:
            return <Typography.Text type="secondary">Workspace creation complete</Typography.Text>;
        case WorkspaceCreationProgress.ERROR:
            return <Typography.Text type="danger">Workspace creation failed</Typography.Text>;
        case WorkspaceCreationProgress.IN_PROGRESS:
            if (!workspaceCreationProgressContent.percent_done || workspaceCreationProgressContent.percent_done <= 0) {
                return (
                    <>
                        <Typography.Text type="secondary">Creating workspace...</Typography.Text>
                        <Typography.Text type="secondary" style={{ fontSize: "0.8em", display: "block" }}>
                            This will only be needed occasionally.
                        </Typography.Text>
                    </>
                );
            } else if (workspaceCreationProgressContent.percent_done < 100) {
                return (
                    <>
                        <Typography.Text type="secondary">{`Creating workspace: ${workspaceCreationProgressContent.percent_done}% complete`}</Typography.Text>
                        <Typography.Text type="secondary" style={{ fontSize: "0.8em", display: "block" }}>
                            This will only be needed occasionally.
                        </Typography.Text>
                        <Progress
                            percent={workspaceCreationProgressContent.percent_done}
                            showInfo={false}
                            strokeColor={getComputedStyle(document.documentElement).getPropertyValue("--solver-blue")}
                            status="active"
                        />
                    </>
                );
            } else {
                return <Typography.Text type="secondary">Workspace creation complete</Typography.Text>;
            }
        default:
            return <Typography.Text type="secondary">{workspaceCreationProgressContent.status}</Typography.Text>;
    }
};

export default WorkspaceProgress;
