import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import ReactMarkdown from "react-markdown";
import faqContents from "../data/faq.txt";

interface FAQModalProps {
    open: boolean;
    onClose: () => void;
}

const FAQModal: React.FC<FAQModalProps> = ({ open, onClose }) => {
    const [faqContent, setFaqContent] = useState<string>("");

    useEffect(() => {
        fetch(faqContents)
            .then((response) => response.text())
            .then((text) => setFaqContent(text));
    }, []);

    return (
        <Modal open={open} onCancel={onClose} footer={null} className="faq-modal" centered width={"50%"}>
            <ReactMarkdown
                components={{
                    a: ({ children, ...props }) => {
                        return (
                            <a href={props.href} target="_blank" rel="noopener">
                                {children}
                            </a>
                        );
                    },
                }}
            >
                {faqContent}
            </ReactMarkdown>
        </Modal>
    );
};

export default FAQModal;
