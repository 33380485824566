"use client";
import { FilterOutlined, LoadingOutlined, PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Divider, Input, Menu, Popover, Tooltip } from "antd";
import { NotificationInstance } from "antd/lib/notification/interface";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { ListChildComponentProps, ListOnScrollProps, VariableSizeList } from "react-window";
import { AuthorSelectionFilter, useSessionBrowsingContext } from "../data/SessionBrowsing";
import { Session } from "../data/SolverSession";
import { useDebounce } from "../hooks/useDebounce";

import FilterList from "./FilterList";
import SessionCard from "./SessionCard";
import "./SessionsList.css";

import { getBranches, Repo } from "../data/Repos";
import { SessionStatus } from "../data/SolverSession";
import { User } from "../data/User";

import { startOfDay, subDays, subMonths, subYears } from "date-fns";
import "./SessionsList.css";

const propertyValueAsInt = (property: string) => {
    return parseInt(getComputedStyle(document.documentElement).getPropertyValue(property));
};

const getSessionCardTotalHeight = () => {
    return propertyValueAsInt("--inactive-session-card-height");
};

const groupSessionsByTimeBuckets = (sessions: Session[]) => {
    const now = new Date();
    const today = startOfDay(now);
    const yesterday = subDays(today, 1);
    const sevenDaysAgo = subDays(today, 7);
    const thirtyDaysAgo = subDays(today, 30);
    const oneYearAgo = subYears(now, 1);

    // Initialize base time buckets
    const groupedSessions: { [key: string]: Session[] } = {
        Today: [],
        Yesterday: [],
        "Previous 7 days": [],
        "Previous 30 days": [],
    };

    // Add month buckets for last 12 months
    [...Array(12)].forEach((_, i) => {
        const monthDate = subMonths(now, i);
        const monthName = monthDate.toLocaleString("default", { month: "long" });
        groupedSessions[monthName] = [];
    });

    // Add a bucket for older sessions
    groupedSessions["Last Year"] = [];

    // Group sessions into buckets
    sessions.forEach((session) => {
        const sessionDate = new Date(session.modify_timestamp * 1000);

        if (sessionDate >= today) {
            groupedSessions["Today"].push(session);
        } else if (sessionDate >= yesterday) {
            groupedSessions["Yesterday"].push(session);
        } else if (sessionDate >= sevenDaysAgo) {
            groupedSessions["Previous 7 days"].push(session);
        } else if (sessionDate >= thirtyDaysAgo) {
            groupedSessions["Previous 30 days"].push(session);
        } else if (sessionDate >= oneYearAgo) {
            const monthName = sessionDate.toLocaleString("default", { month: "long" });
            if (groupedSessions[monthName]) {
                groupedSessions[monthName].push(session);
            }
        } else {
            groupedSessions["Last Year"].push(session);
        }
    });

    // Create new object with only non-empty buckets
    return Object.fromEntries(Object.entries(groupedSessions).filter(([_, sessions]) => sessions.length > 0));
};

interface SessionsListItemData {
    items: Array<{ type: "header"; label: string } | { type: "session"; session: Session }>;
    onSwitchSession: (session: Session) => Promise<void>;
    onUpdateTitle: (session: Session, newName: string) => Promise<boolean>;
    onDeleteSession: (session: Session) => void;
    onCloneSession: (session: Session) => void;
    notification: NotificationInstance;
    currentUser: User | undefined;
    setSize: (index: number) => void;
    activeSession?: Session;
}

const SessionsListRow = React.memo(({ index, style, data }: ListChildComponentProps<SessionsListItemData>) => {
    const item = data.items[index];
    const { onSwitchSession, onUpdateTitle, onDeleteSession, onCloneSession, notification, currentUser } = data;

    const content =
        item.type === "header" ? (
            <div className="time-bucket-header" style={style}>
                {item.label}
            </div>
        ) : (
            <li
                className={`session-list-item`}
                onClick={(e) => {
                    // Don't switch if user is selecting text
                    if (document.getSelection()?.toString() !== "") return;

                    onSwitchSession(item.session);
                    e.preventDefault();
                }}
                style={style}
            >
                <SessionCard
                    currentUser={currentUser}
                    session={item.session}
                    selected={false}
                    notification={notification}
                    onUpdateTitle={(newName) => onUpdateTitle(item.session, newName)}
                    onDelete={() => onDeleteSession(item.session)}
                    onClone={() => onCloneSession(item.session)}
                    editable={
                        currentUser?.id === item.session.user_id &&
                        item.session.session_id !== data.activeSession?.session_id
                    }
                />
            </li>
        );

    return content;
});

interface SessionsListProps {
    currentUser: User | undefined;
    repo: Repo;
    activeSession: Session | undefined;
    activeSessionStatus: SessionStatus;
    onNewSession: (branch: string) => Promise<void>;
    onSwitchSession: (session: Session) => Promise<void>;
    onUpdateTitle: (session: Session, newName: string) => Promise<boolean>;
    onDeleteSession: (session: Session) => void;
    onCloneSession: (session: Session) => void;
    notification: NotificationInstance;
}

const SessionsList: React.FC<SessionsListProps> = ({
    currentUser,
    repo,
    activeSession,
    activeSessionStatus,
    onNewSession,
    onSwitchSession,
    onUpdateTitle,
    onDeleteSession,
    onCloneSession,
    notification,
}) => {
    const [createInProgress, setCreateInProgress] = React.useState<boolean>(false);
    const [branches, setBranches] = React.useState<string[]>([]);
    const [branchFilter, setBranchFilter] = React.useState<string>("");
    const [filteredBranches, setFilteredBranches] = React.useState<string[]>([]);
    const [isNewSessionPopoverOpen, setIsNewSessionPopoverOpen] = React.useState<boolean>(false);
    const [loadingBranches, setLoadingBranches] = React.useState<boolean>(false);

    const {
        sessions,
        loadingSessions,
        page,
        haveMoreSessions,
        titleFilter,
        setTitleFilter,
        authorSelectionFilter,
        setAuthorSelectionFilter,
        loadSessions,
        loadMoreSessions,
    } = useSessionBrowsingContext();

    const debouncedLoadSessions = useDebounce(() => {
        loadSessions(repo.org, repo.name);
    }, 1000);

    useEffect(() => {
        setAuthorSelectionFilter(AuthorSelectionFilter.ALL);
        setLoadingBranches(true);
        getBranches(repo.org, repo.name, 250)
            .then((branches) => {
                setBranches(branches);
                setFilteredBranches(branches);
            })
            .catch(() => {
                notification.error({
                    message: "Failed to load branches",
                    placement: "bottomRight",
                });
            })
            .finally(() => {
                setLoadingBranches(false);
            });
        setBranchFilter("");
    }, [repo]);

    useEffect(() => {
        const filtered = branches.filter((branch) => branch.toLowerCase().includes(branchFilter.toLowerCase()));
        setFilteredBranches(filtered);
    }, [branches, branchFilter]);

    const listableSessions = useMemo(() => {
        return sessions.filter((session: Session) => session.session_id !== activeSession?.session_id);
    }, [sessions, activeSession]);

    const [listHeight, setListHeight] = useState<number>(
        document.body.scrollHeight -
            propertyValueAsInt("--app-header-height") -
            propertyValueAsInt("--sessions-list-header-height")
    );

    useLayoutEffect(() => {
        const updateListHeight = () => {
            let height =
                window.innerHeight -
                propertyValueAsInt("--app-header-height") -
                propertyValueAsInt("--sessions-list-header-height");

            if (activeSession) {
                height -= propertyValueAsInt("--active-session-card-height");
                height -= propertyValueAsInt("--sessions-list-divider-height");
            }

            setListHeight(height);
        };

        updateListHeight();

        window.addEventListener("resize", updateListHeight);

        return () => {
            window.removeEventListener("resize", updateListHeight);
        };
    }, [activeSession]);

    const createNewSession = useCallback(
        async (branch?: string) => {
            if (createInProgress) return;

            const branchToUse = branch || repo.default_branch;
            if (!branchToUse) {
                notification.error({
                    message: "No branch selected",
                    description: "Please select a branch before creating a session.",
                    placement: "bottomRight",
                });
                return;
            }

            setCreateInProgress(true);
            try {
                await onNewSession(branchToUse);
                setIsNewSessionPopoverOpen(false);
                setBranchFilter("");
            } catch (error) {
                notification.error({
                    message: "Failed to create session",
                    description: error instanceof Error ? error.message : "An unknown error occurred",
                    placement: "bottomRight",
                });
            } finally {
                setCreateInProgress(false);
            }
        },
        [createInProgress, onNewSession, repo.default_branch]
    );

    const LOAD_MORE_THRESHOLD = 100;

    const getTimeHeaderHeight = () => propertyValueAsInt("--time-bucket-header-height");

    const getScrollHeight = useCallback((sessions: Session[]): number => {
        const totalSessionsHeight = sessions.length * getSessionCardTotalHeight();
        const totalHeadersHeight = Object.keys(groupSessionsByTimeBuckets(sessions)).length * getTimeHeaderHeight();
        return totalSessionsHeight + totalHeadersHeight;
    }, []);

    const onScrollList = useCallback(
        (scrollProps: ListOnScrollProps) => {
            if (loadingSessions || !haveMoreSessions) return;

            const { scrollOffset, scrollDirection } = scrollProps;
            const scrollHeight = getScrollHeight(listableSessions);
            const isNearBottom = scrollHeight - scrollOffset <= listHeight + LOAD_MORE_THRESHOLD;

            if (scrollHeight > listHeight && isNearBottom && scrollDirection === "forward") {
                loadMoreSessions(repo.org, repo.name);
            }
        },
        [
            loadingSessions,
            haveMoreSessions,
            loadMoreSessions,
            repo.org,
            repo.name,
            listHeight,
            listableSessions,
            getScrollHeight,
        ]
    );

    const buildNewSessionButton = () => {
        return (
            <Popover
                placement="bottomLeft"
                trigger="click"
                content={buildNewSessionList()}
                arrow={false}
                open={isNewSessionPopoverOpen}
                onOpenChange={(open) => setIsNewSessionPopoverOpen(open)}
            >
                <Tooltip title="Create new session" arrow={false} placement="right">
                    <Button id="tour-create-session" icon={<PlusCircleOutlined />} loading={createInProgress} />
                </Tooltip>
            </Popover>
        );
    };

    const buildNewSessionList = () => {
        const handleMenuClick = ({ key }: { key: string }) => {
            if (key !== "no-results") {
                createNewSession(key);
            }
        };

        if (loadingBranches) {
            return (
                <div className="new-session-loading-branches">
                    Loading branches...
                    <LoadingOutlined />
                </div>
            );
        }

        return (
            <div className="new-session-dropdown-wrapper">
                <div className="new-session-instructions">
                    Select a branch to use as the base revision of the new session
                </div>
                <Input
                    className="new-session-dropdown-input"
                    placeholder="Search branches"
                    value={branchFilter}
                    addonBefore={<SearchOutlined />}
                    onChange={(e) => setBranchFilter(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter" && filteredBranches.length === 1) {
                            createNewSession(filteredBranches[0]);
                        }
                    }}
                />
                <Menu
                    onClick={handleMenuClick}
                    className="new-session-dropdown-menu scrollbar scrollbar-gutter-stable-both"
                    selectable={false}
                >
                    {filteredBranches.length === 0 ? (
                        <Menu.Item key="no-results" disabled>
                            No matching branches
                        </Menu.Item>
                    ) : (
                        <>
                            {repo.default_branch && filteredBranches.includes(repo.default_branch) && (
                                <>
                                    <Menu.Item key={repo.default_branch}>
                                        <div className="new-session-dropdown-menu-item-inner">
                                            <div>{repo.default_branch}</div>
                                            <div className="new-session-default-branch-badge">default</div>
                                        </div>
                                    </Menu.Item>
                                    <Menu.Divider />
                                </>
                            )}
                            {filteredBranches
                                .filter((branch) => branch !== repo.default_branch)
                                .map((branch) => (
                                    <Menu.Item key={branch}>
                                        <div className="new-session-dropdown-menu-item-inner">
                                            <div>{branch}</div>
                                        </div>
                                    </Menu.Item>
                                ))}
                        </>
                    )}
                </Menu>
            </div>
        );
    };

    const buildActiveSessionCard = () => {
        if (!activeSession) return null;

        activeSession.status = activeSessionStatus;

        return (
            <>
                <div className="active-session-card-wrapper">
                    <SessionCard
                        currentUser={currentUser}
                        session={activeSession}
                        selected={true}
                        notification={notification}
                        onUpdateTitle={(newName) => onUpdateTitle(activeSession, newName)}
                        onDelete={() => onDeleteSession(activeSession)}
                        onClone={() => onCloneSession(activeSession)}
                        editable={currentUser?.id === activeSession.user_id}
                    />
                </div>
                <Divider type={"horizontal"} className="session-list-divider" />
            </>
        );
    };

    const listRef = useRef<VariableSizeList>(null);

    const setSize = useCallback((index: number) => {
        if (listRef.current) {
            listRef.current.resetAfterIndex(index, false);
        }
    }, []);

    const listItems = useMemo(() => {
        const sortedGroupedSessions = groupSessionsByTimeBuckets(listableSessions);
        return Object.entries(sortedGroupedSessions).flatMap(([label, sessions]) => [
            { type: "header" as const, label },
            ...sessions.map((session) => ({ type: "session" as const, session })),
        ]);
    }, [listableSessions]);

    // Reset list when sessions change
    useEffect(() => {
        if (listRef.current) {
            listRef.current.resetAfterIndex(0);
        }
    }, [listItems]);

    const listData: SessionsListItemData = useMemo(
        () => ({
            onSwitchSession,
            onUpdateTitle,
            onDeleteSession,
            onCloneSession,
            notification,
            currentUser,
            setSize,
            items: listItems,
            activeSession,
        }),
        [
            onSwitchSession,
            onUpdateTitle,
            onDeleteSession,
            onCloneSession,
            notification,
            currentUser,
            setSize,
            listItems,
            activeSession,
        ]
    );

    const buildList = useCallback(() => {
        if (loadingSessions && page < 1) {
            return (
                <div style={{ textAlign: "center" }}>
                    <LoadingOutlined />
                </div>
            );
        }

        if (sessions.length === 0) {
            return <div style={{ textAlign: "center" }}>No sessions found</div>;
        }

        const getItemSize = (item: (typeof listItems)[number]) =>
            item.type === "header" ? getTimeHeaderHeight() : getSessionCardTotalHeight();

        return (
            <VariableSizeList
                ref={listRef}
                className="scrollbar scrollbar-gutter-stable"
                height={listHeight}
                itemCount={listItems.length}
                itemSize={(index) => getItemSize(listItems[index])}
                itemData={{ ...listData, items: listItems }}
                width="100%"
                onScroll={onScrollList}
            >
                {SessionsListRow}
            </VariableSizeList>
        );
    }, [loadingSessions, page, sessions.length, listHeight, onScrollList, listData, listItems]);

    const buildFilterList = () => {
        return <div className="session-filter-list">{buildFilterListContent()}</div>;
    };

    const buildFilterListContent = () => {
        return (
            <FilterList
                items={[
                    { key: AuthorSelectionFilter.ALL, label: "All Sessions" },
                    { key: AuthorSelectionFilter.MINE, label: "My Sessions" },
                ]}
                selectedKeys={[authorSelectionFilter]}
                onSelect={(key) => {
                    setAuthorSelectionFilter(key as AuthorSelectionFilter);

                    debouncedLoadSessions();
                }}
                buttonStyle="radio"
            />
        );
    };

    return (
        <>
            <div className="session-list-header">
                <Input
                    value={titleFilter}
                    onChange={(e) => {
                        setTitleFilter(e.target.value);
                        debouncedLoadSessions();
                    }}
                    placeholder="Search sessions"
                    addonBefore={<SearchOutlined />}
                    addonAfter={
                        <Popover arrow={false} trigger={"click"} content={buildFilterList()} placement="bottomRight">
                            <Tooltip title="Filter" arrow={false} placement="right">
                                <Button type="text" size="small" icon={<FilterOutlined />} />
                            </Tooltip>
                        </Popover>
                    }
                    allowClear
                />
                {buildNewSessionButton()}
            </div>
            {buildActiveSessionCard()}
            {buildList()}
        </>
    );
};

export default SessionsList;
