import mermaid from "mermaid";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

interface MermaidDiagramProps {
    diagram: string;
}

const diagramCache = new Map<string, string>();

// Properly typed debounce function without any
const debounce = <F extends (...args: never[]) => unknown>(func: F, delay: number) => {
    let timeoutId: NodeJS.Timeout;
    return (...args: Parameters<F>): void => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => func(...args), delay);
    };
};

const MermaidDiagram: React.FC<MermaidDiagramProps> = React.memo(({ diagram }) => {
    const [svgContent, setSvgContent] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [mermaidInitialized, setMermaidInitialized] = useState(false);

    const memoizedDiagram = useMemo(() => diagram, [diagram]);

    const initializeMermaid = useCallback(() => {
        if (!mermaidInitialized) {
            mermaid.initialize({
                startOnLoad: false,
                theme: "dark",
                themeVariables: {
                    background: "#252525",
                    primaryColor: "#FF6B6B",
                    secondaryColor: "#4ECDC4",
                    tertiaryColor: "#45B7D1",
                    primaryBorderColor: "#FF6B6B",
                    secondaryBorderColor: "#4ECDC4",
                    tertiaryBorderColor: "#45B7D1",
                    lineColor: "#F7FFF7",
                    textColor: "#F7FFF7",
                },
            });
            setMermaidInitialized(true);
        }
    }, [mermaidInitialized]);

    const renderDiagram = useCallback(
        async (diagramContent: string) => {
            initializeMermaid();

            if (diagramCache.has(diagramContent)) {
                setSvgContent(diagramCache.get(diagramContent)!);
                return;
            }

            try {
                const { svg } = await mermaid.render("mermaid-diagram", diagramContent);
                diagramCache.set(diagramContent, svg);
                setSvgContent(svg);
                setError(null);
            } catch (err) {
                console.error("Mermaid rendering failed:", err);
                setError(String(err));
            }
        },
        [initializeMermaid]
    );

    const debouncedRenderDiagram = useMemo(() => debounce(renderDiagram, 300), [renderDiagram]);

    useEffect(() => {
        debouncedRenderDiagram(memoizedDiagram);
    }, [memoizedDiagram, debouncedRenderDiagram]);

    const removeSvgBorder = useCallback(() => {
        if (containerRef.current) {
            const svg = containerRef.current.querySelector("svg");
            if (svg) {
                svg.style.border = "none";
                svg.style.backgroundColor = "transparent";
            }
        }
    }, []);

    useEffect(() => {
        if (svgContent) {
            removeSvgBorder();
        }
    }, [svgContent, removeSvgBorder]);

    if (error) {
        return (
            <div>
                <p>Error rendering diagram:</p>
                <pre>{error}</pre>
                <p>Raw diagram:</p>
                <pre>{memoizedDiagram}</pre>
            </div>
        );
    }

    return (
        <div style={{ width: "100%", overflow: "auto" }}>
            {svgContent ? (
                <div
                    ref={containerRef}
                    dangerouslySetInnerHTML={{ __html: svgContent }}
                    style={{
                        minHeight: "200px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                />
            ) : (
                <div>Loading diagram...</div>
            )}
        </div>
    );
});

MermaidDiagram.displayName = "MermaidDiagram";

export default MermaidDiagram;
