import React, { createContext, useEffect, useState } from "react";
import axios, { AxiosError, AxiosResponse } from "axios";
import { notification } from "antd";
import qs from "qs";
import { useCookies } from "react-cookie";

import { AppPath, parseAppPath, NavigationBehavior, navigateToRepo, navigateToRoot } from "./Navigation";
import { getRepo, getRepos, GetRepoResponse, GetReposResponse, Repo } from "./Repos";
import { SolverInterfaceEventType } from "./SolverInterfaceEvent";
import { SOLVER_INTERFACE_URL_BASE, solverInterfaceApiAxios } from "./SolverInterfaceConstants";
import { AuthType, User } from "./User";

import {
    useStreamConnection,
    SolverInterfaceEventObserver,
    SolverInterfaceEventObserverHandle,
    StreamConnectionStatus,
} from "../hooks/useStreamConnection";

export enum SolverInterfaceStatus {
    LOGGED_IN,
    LOGGED_OUT,
    USER_NOT_ALLOWLISTED,
    LOADING,
    FAILED_TO_LOAD_REPOS,
    SIGN_UP_TOKEN_INVALID,
    ERROR,
}

export interface ConnectToSolverInterfaceArgs {
    requestedOrg?: string;
    requestedRepo?: string;
    signUpToken?: string;
}

type GetUserResult = {
    user: User | undefined;
    solverInterfaceStatus: SolverInterfaceStatus;
};

export type SolverInterfaceContextType = {
    solverInterfaceStatus: SolverInterfaceStatus;
    loggedIn: boolean;
    userNotAllowListed: boolean;
    appIsReady: boolean;
    currentUser: User | undefined;
    login: (authType: AuthType, signUpToken?: string) => void;
    logout: () => void;
    signUpToken: string | undefined;
    onSignUpTokenInvalid: () => void;
    repos: Repo[];
    loadingRepos: boolean;
    loadingAdditionalRepos: boolean;
    activeRepo: Repo | undefined;
    setActiveRepo: (repo: Repo, navigationBehavior: NavigationBehavior) => void;
    connectToSolverInterface: (connectToSolverInterfaceArgs: ConnectToSolverInterfaceArgs) => Promise<boolean>;
    addSolverInterfaceEventObserver: (
        solverInterfaceEventType: SolverInterfaceEventType,
        observer: SolverInterfaceEventObserver
    ) => SolverInterfaceEventObserverHandle;
    removeSolverInterfaceEventObserver: (handle: SolverInterfaceEventObserverHandle) => void;
    onStreamConnectionErrorResponse: (status: number) => void;
    onStreamReconnectionFailed: () => void;
    onRepoNotFound: (repoName: string) => void;
};

const SolverInterfaceContext = createContext<SolverInterfaceContextType | undefined>(undefined);

const SolverInterfaceContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [solverInterfaceStatus, setSolverInterfaceStatus] = useState<SolverInterfaceStatus>(
        SolverInterfaceStatus.LOADING
    );
    const [currentUser, setCurrentUser] = useState<User | undefined>(undefined);

    const [activeRepo, setActiveRepo] = useState<Repo | undefined>(undefined);

    const [signUpToken, setSignUpToken] = useState<string | undefined>(undefined);

    const [loadingRepos, setLoadingRepos] = useState<boolean>(true);
    const [loadingAdditionalRepos, setLoadingAdditionalRepos] = useState<boolean>(true);
    const [repos, setRepos] = useState<Repo[]>([]);
    const [cookie, setCookie, removeCookie] = useCookies(["lastRepo"]);

    const [api, contextHolder] = notification.useNotification();

    const loggedIn = currentUser !== undefined;
    const userNotAllowListed = solverInterfaceStatus === SolverInterfaceStatus.USER_NOT_ALLOWLISTED;

    const onStreamConnectionErrorResponse = (status: number) => {
        switch (status) {
            case 401:
                onUnauthenticated();
                break;
            case 403:
                onUnauthorized();
                break;
            default:
                console.warn("Unexpected response code from /api/connect:", status);

                setSolverInterfaceStatus(SolverInterfaceStatus.ERROR);
        }
    };

    const onStreamReconnectionFailed = () => {
        api.error({
            message: "Connection lost",
            description: "Please try refreshing the page",
            placement: "bottomRight",
            duration: null,
            key: "reconnection-failed",
        });
    };

    const onUnauthenticated = () => {
        api.error({
            message: "You have been logged out",
            placement: "bottomRight",
            duration: null,
            key: "unauthorized",
        });

        logout();
    };

    const onUnauthorized = () => {
        api.error({
            message: "Unauthorized",
            description: "You do not have access.",
            placement: "bottomRight",
            duration: 3,
            key: "unauthorized",
        });

        setSolverInterfaceStatus(SolverInterfaceStatus.ERROR);
    };

    const onRepoNotFound = (repoName: string) => {
        api.error({
            message: `Repo ${repoName} not found`,
            placement: "bottomRight",
            duration: 3,
            key: "repo-not-found",
        });

        setActiveRepoFn(undefined, NavigationBehavior.REPLACE);
    };

    const { connect, streamConnectionStatus, addSolverInterfaceEventObserver, removeSolverInterfaceEventObserver } =
        useStreamConnection(onStreamConnectionErrorResponse, onStreamReconnectionFailed);

    const appIsReady = loggedIn && !!activeRepo && streamConnectionStatus === StreamConnectionStatus.CONNECTED;

    useEffect(() => {
        const interceptor = solverInterfaceApiAxios.interceptors.response.use(
            (response: AxiosResponse) => response,
            (error: AxiosError) => {
                if (error.response && error.response.status === 401) {
                    onUnauthenticated();
                } else if (error.response && error.response.status === 403) {
                    onUnauthorized();
                } else if (error.response && error.response.status >= 500) {
                    // Without this, axios will eat any 5XX, making us unable to handle them ourselves.
                    return Promise.reject(error);
                }

                return Promise.reject(error);
            }
        );

        return () => {
            solverInterfaceApiAxios.interceptors.response.eject(interceptor);
        };
    }, []);

    useEffect(() => {
        if (activeRepo) {
            connect(`${SOLVER_INTERFACE_URL_BASE}/api/connect/repos/${activeRepo.org}/${activeRepo.name}`);
        }
    }, [activeRepo]);

    const connectToSolverInterface = async ({
        requestedOrg,
        requestedRepo,
        signUpToken,
    }: ConnectToSolverInterfaceArgs): Promise<boolean> => {
        setSignUpToken(signUpToken);
        const getUserResult: GetUserResult = await getUser();

        setCurrentUser(getUserResult.user);
        setSolverInterfaceStatus(getUserResult.solverInterfaceStatus);

        if (getUserResult.solverInterfaceStatus === SolverInterfaceStatus.LOGGED_IN) {
            let loadedRequestedRepo = false;
            if (requestedOrg && requestedRepo) {
                loadedRequestedRepo = await loadRequestedRepo(requestedOrg, requestedRepo);
            }

            // Not awaiting this because the return value indicates whether the requested repo was loaded
            // so app initialization is fast tracked after the requested repo is loaded.
            updateRepos(loadedRequestedRepo);

            return loadedRequestedRepo;
        } else if (
            getUserResult.solverInterfaceStatus === SolverInterfaceStatus.USER_NOT_ALLOWLISTED &&
            signUpToken &&
            getUserResult.user
        ) {
            // The user could be logged in but not allowlisted, so we want to redeem their sign up token via
            // the /login -> oauth -> /oauth_redirect flow.
            login(getUserResult.user.auth_type, signUpToken);
            return false;
        } else {
            return false;
        }
    };

    const setActiveRepoFn = (repo: Repo | undefined, navigationBehavior: NavigationBehavior) => {
        setActiveRepo(repo);
        if (repo) {
            setCookie("lastRepo", repo.full_name, { path: "/", maxAge: 60 * 60 * 24 * 30 });

            navigateToRepo(repo.full_name, navigationBehavior);
        } else {
            removeCookie("lastRepo", { path: "/" });
        }
    };

    const loadRequestedRepo = async (requestedOrg: string, requestedRepo: string): Promise<boolean> => {
        const getRepoResponse: GetRepoResponse = await getRepo(requestedOrg, requestedRepo);

        if (getRepoResponse.error || !getRepoResponse.repo) {
            onRepoNotFound(`${requestedOrg}/${requestedRepo}`);

            return false;
        }

        const repo = getRepoResponse.repo;
        setRepos([repo]);
        setActiveRepoFn(repo, NavigationBehavior.NONE);

        return true;
    };

    const updateRepos = async (loadedRequestedRepo: boolean): Promise<void> => {
        setLoadingRepos(!loadedRequestedRepo);

        const response: GetReposResponse = await getRepos();

        const loadedRepos = response.repos;

        setRepos(loadedRepos);
        setLoadingRepos(false);
        setLoadingAdditionalRepos(false);

        if (response.error) {
            setSolverInterfaceStatus(SolverInterfaceStatus.FAILED_TO_LOAD_REPOS);
            return;
        }

        if (loadedRepos && loadedRepos.length > 0) {
            const repo = loadedRepos.find((repo) => repo.full_name === cookie.lastRepo);

            if (!loadedRequestedRepo) {
                if (repo) {
                    setActiveRepoFn(repo, NavigationBehavior.REPLACE);
                } else {
                    setActiveRepoFn(loadedRepos[0], NavigationBehavior.REPLACE);
                }
            }
        } else {
            setActiveRepoFn(undefined, NavigationBehavior.REPLACE);
        }
    };

    const login = (authType: AuthType, signUpToken?: string) => {
        const currentLocation = window.location;

        const appPath: AppPath = parseAppPath(currentLocation.pathname, currentLocation.search);
        let redirectTo = currentLocation.origin;
        if (appPath.repo) {
            redirectTo += `/${appPath.repo}`;

            if (appPath.session) {
                redirectTo += `/${appPath.session}`;
            }
        }

        const params = qs.stringify({
            auth_type: authType,
            sign_up_token: signUpToken,
            redirect_to: redirectTo,
        });

        setSolverInterfaceStatus(SolverInterfaceStatus.LOADING);

        // Using fetch because axios doesn't support manual redirects. This flow will hit /login twice.
        // The first request will check that the token is valid. If the token is valid, the second
        // will do a page navigation to /login and redirect to the OAuth provider.
        fetch(`${SOLVER_INTERFACE_URL_BASE}/login?${params}`, {
            method: "GET",
            // Prevents the browser from following the redirect. We want to handle it ourselves
            // since fetch by default handles the redirect as a data response instead of a document load.
            redirect: "manual",
        })
            .then((response) => {
                if (response.status === 400) {
                    onSignUpTokenInvalid();
                } else {
                    const anchor = document.createElement("a");
                    anchor.href = response.url;
                    anchor.click();
                }
            })
            .catch((error) => {
                console.error("Error logging in", error);
                setSolverInterfaceStatus(SolverInterfaceStatus.ERROR);
            });
    };

    const logout = async (): Promise<SolverInterfaceStatus> => {
        try {
            setSolverInterfaceStatus(SolverInterfaceStatus.LOADING);
            return axios.post(`${SOLVER_INTERFACE_URL_BASE}/logout`, {}, { withCredentials: true }).then(() => {
                setSolverInterfaceStatus(SolverInterfaceStatus.LOGGED_OUT);
                return SolverInterfaceStatus.LOGGED_OUT;
            });
        } catch (error) {
            return SolverInterfaceStatus.ERROR;
        } finally {
            setCurrentUser(undefined);
            setActiveRepoFn(undefined, NavigationBehavior.NONE);
            navigateToRoot(NavigationBehavior.PUSH);
            removeCookie("lastRepo", { path: "/" });
        }
    };

    const onSignUpTokenInvalid = () => {
        setSolverInterfaceStatus(SolverInterfaceStatus.SIGN_UP_TOKEN_INVALID);
    };

    const value = {
        solverInterfaceStatus,
        loggedIn,
        userNotAllowListed,
        appIsReady,
        currentUser,
        login,
        logout,
        signUpToken,
        onSignUpTokenInvalid,
        connectToSolverInterface,
        repos,
        loadingRepos,
        loadingAdditionalRepos,
        activeRepo,
        setActiveRepo: setActiveRepoFn,
        addSolverInterfaceEventObserver,
        removeSolverInterfaceEventObserver,
        onStreamConnectionErrorResponse,
        onStreamReconnectionFailed,
        onRepoNotFound,
    };

    return (
        <SolverInterfaceContext.Provider value={value}>
            {contextHolder}
            {children}
        </SolverInterfaceContext.Provider>
    );
};

const useSolverInterfaceContext = (): SolverInterfaceContextType => {
    const ctx = React.useContext(SolverInterfaceContext);
    if (!ctx) {
        throw new Error("useSolverInterfaceContext must be used within an SolverInterfaceContextProvider");
    }
    return ctx;
};

const getUser = (): Promise<GetUserResult> => {
    return axios
        .get(`${SOLVER_INTERFACE_URL_BASE}/user`, { withCredentials: true })
        .then((response) => {
            const user: User = response.data as User;

            return {
                user,
                solverInterfaceStatus: user.allowlisted
                    ? SolverInterfaceStatus.LOGGED_IN
                    : SolverInterfaceStatus.USER_NOT_ALLOWLISTED,
            };
        })
        .catch((error) => {
            if (error.response?.status === 401) {
                return {
                    user: undefined,
                    solverInterfaceStatus: SolverInterfaceStatus.LOGGED_OUT,
                };
            }

            console.error("Error getting user", error);
            return {
                user: undefined,
                solverInterfaceStatus: SolverInterfaceStatus.ERROR,
            };
        });
};

export { SolverInterfaceContextProvider, useSolverInterfaceContext };
