"use client";
import { LeftOutlined, LoadingOutlined, RightOutlined } from "@ant-design/icons";
import { Layout, Radio } from "antd";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { expandFromRawCode, ViewType } from "react-diff-view";
import { ChangeSet, getChanges, SessionInfo, useSession } from "../data/SolverSession";
import ChangedFileTree from "./ChangedFileTree";
import { ImmutableDiffCard } from "./ImmutableDiffCard";
import { ChangeSetSummary, FileInfo, getRelevantPath } from "./Utils";

import "./ChangesView.css";

const { Sider, Content } = Layout;

interface ChangesViewProps {
    restoreScrollPosition: () => void;
}

const getReviewChanges = async (sessionInfo: SessionInfo): Promise<ChangeSet> => {
    try {
        return await getChanges(sessionInfo, {
            include_preimage: true,
            include_postimage: false,
        });
    } catch (error) {
        throw error;
    }
};

const ChangesView: React.FC<ChangesViewProps> = ({ restoreScrollPosition }) => {
    const [reviewChanges, setReviewChanges] = useState<ChangeSet | null>(null);
    const [viewType, setViewType] = useState<ViewType>(() => {
        const storedViewType = localStorage.getItem("changesViewType");
        return (storedViewType === "split" ? "split" : "unified") as ViewType;
    });
    const [selectedFile, setSelectedFile] = useState<string | null>(null);
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    const session = useSession();
    const contentRef = useRef<HTMLDivElement>(null);
    const fileRefs = useRef<Record<string, React.RefObject<HTMLDivElement>>>({});

    const getFileRef = (filePath: string) => {
        if (!fileRefs.current[filePath]) {
            fileRefs.current[filePath] = React.createRef<HTMLDivElement>();
        }
        return fileRefs.current[filePath];
    };

    useEffect(() => {
        if (session) {
            getReviewChanges(session.getInfo()).then(setReviewChanges);
        }
    }, [session]);

    useEffect(() => {
        setReviewChanges(null);
    }, []);

    useLayoutEffect(() => {
        restoreScrollPosition();
    }, [reviewChanges]);

    const expandReviewChangesFile = (file_path: string, start: number, end: number) => {
        setReviewChanges((prevReviewChanges) => {
            if (!prevReviewChanges) return null;

            const newFileInfos = prevReviewChanges.file_infos.map((fi) => {
                if (getRelevantPath(fi.fileData) === file_path) {
                    const source = Array.isArray(fi.source) ? fi.source : (fi.source || "").split("\n");
                    fi.fileData.hunks = expandFromRawCode(fi.fileData.hunks, source, start, end);
                }
                return fi;
            });

            return { ...prevReviewChanges, file_infos: newFileInfos };
        });
    };

    const buildDiffs = (reviewChanges: ChangeSet) => {
        return reviewChanges.file_infos.map((fileInfo: FileInfo) => {
            const fileData = fileInfo.fileData;
            const filePath = getRelevantPath(fileData);
            const diffCardKey = `${fileData.oldRevision}-${fileData.newRevision}`;

            return (
                <div key={diffCardKey} ref={getFileRef(filePath)}>
                    <ImmutableDiffCard
                        fileInfo={fileInfo}
                        expandCodeFn={(start: number, end: number) => expandReviewChangesFile(filePath, start, end)}
                        collapsable={true}
                        viewType={viewType}
                    />
                </div>
            );
        });
    };

    const onTreeFileSelect = (filePath: string) => {
        setSelectedFile(filePath);
        const element = fileRefs.current[filePath]?.current;
        if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    };

    return (
        <Layout className="changes-layout changes-view">
            <Sider
                width={275}
                theme="dark"
                className="changes-sidebar"
                collapsible
                collapsed={sidebarCollapsed}
                onCollapse={setSidebarCollapsed}
                collapsedWidth={0}
                trigger={
                    sidebarCollapsed ? (
                        <RightOutlined className="sider-trigger-icon" />
                    ) : (
                        <LeftOutlined className="sider-trigger-icon" />
                    )
                }
                zeroWidthTriggerStyle={{
                    top: "0px",
                    width: 15,
                    right: "0px",
                    borderRadius: "0",
                    background: "transparent",
                    backgroundColor: "#404040",
                }}
            >
                <ChangedFileTree
                    files={reviewChanges?.file_infos ?? []}
                    onFileSelect={onTreeFileSelect}
                    selectedFile={selectedFile}
                />
            </Sider>
            <Content className="changes-content scrollbar scrollbar-gutter-stable" ref={contentRef}>
                <div className="changes-view-header">
                    <Radio.Group
                        size="small"
                        onChange={(e) => {
                            const newViewType = e.target.value;
                            setViewType(newViewType);
                            localStorage.setItem("changesViewType", newViewType);
                        }}
                        value={viewType}
                        optionType="button"
                    >
                        <Radio.Button value="unified">Unified</Radio.Button>
                        <Radio.Button value="split">Split</Radio.Button>
                    </Radio.Group>
                    {reviewChanges && <ChangeSetSummary changeSet={reviewChanges} />}
                </div>
                {reviewChanges === null ? (
                    <div className="changes-loading">
                        <LoadingOutlined style={{ fontSize: "24px" }} />
                    </div>
                ) : reviewChanges.changes.length === 0 ? (
                    <div className="no-changes-visible">No changes to display.</div>
                ) : (
                    buildDiffs(reviewChanges)
                )}
            </Content>
        </Layout>
    );
};

export default ChangesView;
