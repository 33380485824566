import {
    CheckCircleOutlined,
    ClockCircleOutlined,
    CodeOutlined,
    ContainerOutlined,
    SyncOutlined,
    CopyOutlined,
    DeleteOutlined,
    DownloadOutlined,
    EditOutlined,
    EllipsisOutlined,
    ExclamationCircleOutlined,
    ExportOutlined,
    FlagOutlined,
    LoadingOutlined,
    PullRequestOutlined,
} from "@ant-design/icons";
import { Button, Card, Dropdown, Input, MenuProps, Modal, Tooltip, Typography } from "antd";
import { ModalStaticFunctions } from "antd/es/modal/confirm";
import { NotificationInstance } from "antd/lib/notification/interface";
import classNames from "classnames";
import { formatDistanceToNow } from "date-fns";
import Markdown from "react-markdown";
import React, { useEffect } from "react";
import { isAxiosError } from "axios";

import AvatarVariantFactory from "../data/AvatarVariantFactory";
import {
    CreatePullRequestResponse,
    CreatePullRequestResultCode,
    createPullRequest,
    getPatchContents,
    Session,
    SessionInfo,
    SessionStatus,
    sessionIsLoading,
} from "../data/SolverSession";
import { solverInterfaceApiAxios } from "../data/SolverInterfaceConstants";
import { authTypeDisplayName, branchUrl, pullRequestUrl, User } from "../data/User";
import ReportModal from "./ReportModal";
import TraceableNotificationDescription from "./TraceableNotificationDescription";

import "./SessionCard.css";

const SessionCard: React.FC<{
    currentUser?: User;
    session: Session;
    selected: boolean;
    notification: NotificationInstance;
    onUpdateTitle: (newTitle: string) => Promise<boolean>;
    onDelete: () => void;
    onClone: () => void;
    editable: boolean;
}> = ({ currentUser, session, selected, notification, onUpdateTitle, onDelete, onClone, editable }) => {
    const [title, setTitle] = React.useState<string>(session.title);
    const [editing, setEditing] = React.useState<boolean>(false);
    const [reportModalOpen, setReportModalOpen] = React.useState<boolean>(false);
    const [timeAgoMessage, setTimeAgoMessage] = React.useState<string>(
        formatDistanceToNow(new Date(session.modify_timestamp * 1000), { addSuffix: true })
    );

    const [updatingTitle, setUpdatingTitle] = React.useState<boolean>(false);

    useEffect(() => {
        setTitle(session.title);
    }, [session.title]);

    useEffect(() => {
        const interval = setInterval(
            () =>
                setTimeAgoMessage(formatDistanceToNow(new Date(session.modify_timestamp * 1000), { addSuffix: true })),
            5000
        );
        return () => clearInterval(interval);
    }, [session.modify_timestamp]);

    const renameSession = async (newTitle: string) => {
        if (newTitle === session.title) return;

        const computedTitle = newTitle === "" ? "Untitled Session" : newTitle;

        setUpdatingTitle(true);
        const success = await onUpdateTitle(computedTitle);
        setUpdatingTitle(false);

        setTitle(success ? computedTitle : session.title);
    };

    const [modal, modalContextHolder] = Modal.useModal();
    const onExportDropdownClick: MenuProps["onClick"] = ({ key }) => {
        switch (key) {
            case "file":
                exportToFile(session.getInfo(), notification);
                break;
            case "clipboard":
                exportToClipboard(session.getInfo(), notification, modal);
                break;
            case "pr":
                notifyPullRequestCreation(session, notification, modal);
                break;
            case "sync":
                syncWithRemote(session, notification, modal);
                break;
        }
    };

    const onReportDropdownClick: MenuProps["onClick"] = ({ key }) => {
        if (key === "report") {
            setReportModalOpen(true);
        } else if (key === "copy") {
            navigator.clipboard
                .writeText(session.session_id)
                .then(() =>
                    notification.success({
                        message: "Copied session ID",
                        placement: "bottomRight",
                        duration: 3,
                    })
                )
                .catch(() =>
                    notification.error({
                        message: "Copy failed",
                        description: "Could not copy Session ID to clipboard.",
                        placement: "bottomRight",
                        duration: 3,
                    })
                );
        }
    };

    const getStatusIcon = () => {
        switch (session.status) {
            case SessionStatus.READY:
                return <CheckCircleOutlined className="session-status-icon" />;
            case SessionStatus.ARCHIVED:
                return <ContainerOutlined className="session-status-icon" />;
            case SessionStatus.PENDING:
                return <ClockCircleOutlined className="session-status-icon" />;
            case SessionStatus.SOLVING:
            case SessionStatus.SUBMITTING_CANCEL:
            case SessionStatus.SUBMITTING_SOLVE:
                return <LoadingOutlined className="session-status-icon session-status-icon-loading" />;
            default:
                return <ExclamationCircleOutlined className="session-status-icon" />;
        }
    };

    const getStatusTooltip = () => {
        switch (session.status) {
            case SessionStatus.READY:
                return "Ready to solve";
            case SessionStatus.PENDING:
                return "Pending";
            case SessionStatus.SUBMITTING_SOLVE:
                return "Submitting";
            case SessionStatus.SOLVING:
                return "Solving";
            case SessionStatus.SUBMITTING_CANCEL:
                return "Cancelling";
            case SessionStatus.ARCHIVED:
                return "Archived";
            default:
                return "Unknown status";
        }
    };

    const buildTitleArea = () => {
        return (
            <div className="session-card-title-area">
                <div className="session-card-title">{buildTitle()}</div>
                <div className="session-card-branch-area">
                    <Tooltip
                        title={`${session.branch_name} @ ${session.base_revision.slice(0, 7)}`}
                        arrow={false}
                        placement="topLeft"
                    >
                        <small className="session-card-branch-name">{session.branch_name}</small>
                    </Tooltip>
                    {session.remote_branch_name && (
                        <>
                            &larr;
                            <Tooltip title={session.remote_branch_name} arrow={false} placement="topLeft">
                                <small className="session-card-branch-name">{session.remote_branch_name}</small>
                            </Tooltip>
                        </>
                    )}
                </div>
            </div>
        );
    };

    const buildTitle = () => {
        const titleTextclass = classNames({
            "session-title-text-unselected": !selected,
            "session-title-text-selected": selected,
        });

        if (selected && editable) {
            if (editing) {
                return (
                    <Input
                        value={title}
                        onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === "Escape") {
                                const value = (e.target as HTMLInputElement).value;

                                setEditing(false);
                                renameSession(value);
                            }
                        }}
                        onChange={(value) => setTitle(value.target.value)}
                        autoFocus={true}
                        onBlur={() => {
                            setEditing(false);
                            renameSession(title);
                        }}
                    />
                );
            }

            return (
                <div
                    className="session-title-edit"
                    onClick={(e) => {
                        if (!editable) return;

                        setEditing(true);
                        e.stopPropagation();
                    }}
                >
                    <Tooltip title={title} placement="top" arrow={false}>
                        <Typography.Text
                            onClick={(e) => {
                                setEditing(true);
                                e.stopPropagation();
                            }}
                            className={titleTextclass}
                            ellipsis={true}
                        >
                            {title}
                        </Typography.Text>
                    </Tooltip>
                    <Tooltip title="Edit title" placement="right" arrow={false}>
                        {updatingTitle ? <LoadingOutlined /> : <EditOutlined className="session-title-edit-trigger" />}
                    </Tooltip>
                </div>
            );
        } else {
            return (
                <Tooltip title={title} placement="top" arrow={false}>
                    <Typography.Text className={titleTextclass}>{title}</Typography.Text>
                </Tooltip>
            );
        }
    };

    const buildActionButtons = () => {
        const exportDisabled = sessionIsLoading(session.status);

        const exportClass = classNames({
            "session-card-action-disabled": exportDisabled,
        });

        if (selected) {
            return [
                <Dropdown
                    key={1}
                    arrow={false}
                    disabled={exportDisabled}
                    className={exportClass}
                    menu={{
                        items: [
                            { icon: <DownloadOutlined />, label: "Download patch file", key: "file" },
                            { icon: <CopyOutlined />, label: "Copy patch to clipboard", key: "clipboard" },
                            ...(session.remote_branch_name
                                ? [{ icon: <SyncOutlined />, label: "Sync remote", key: "sync" }]
                                : [{ icon: <PullRequestOutlined />, label: "Open pull request", key: "pr" }]),
                        ],
                        onClick: onExportDropdownClick,
                    }}
                    placement={"bottomRight"}
                >
                    <ExportOutlined className={exportClass} />
                </Dropdown>,
                <Dropdown
                    key={2}
                    arrow={false}
                    menu={{
                        items: [
                            { icon: <FlagOutlined />, label: "Report an issue", key: "report" },
                            { icon: <CopyOutlined />, label: "Copy session ID", key: "copy" },
                        ],
                        onClick: onReportDropdownClick,
                    }}
                    placement="bottomRight"
                >
                    <EllipsisOutlined />
                </Dropdown>,
            ];
        }

        return [];
    };

    const buildCardDescription = () => {
        const avatarUrl = AvatarVariantFactory.createURLVariant(session.user_avatar_url, session.auth_type, 40);

        return (
            <div className="session-card-description">
                <span className="session-card-author">
                    <img className="session-card-author-icon" src={avatarUrl} alt={session.user_name} />
                    <small>{session.user_name}</small>
                </span>
                <small className="session-card-timestamp">Updated {timeAgoMessage}</small>
            </div>
        );
    };

    const buildHoverButton = () => {
        if (editable) {
            return (
                <Tooltip title="Delete" arrow={false} placement="right">
                    <div
                        className="session-card-hover"
                        onClick={(e) => {
                            if (!editable) return;

                            onDelete();
                            e.stopPropagation();
                        }}
                    >
                        <DeleteOutlined />
                    </div>
                </Tooltip>
            );
        } else if (currentUser?.id !== session.user_id && session.status === SessionStatus.READY) {
            return (
                <Tooltip title="Clone" arrow={false} placement="right">
                    <div
                        className="session-card-hover"
                        onClick={(e) => {
                            if (editable) return;

                            onClone();
                            e.stopPropagation();
                        }}
                    >
                        <CodeOutlined />
                    </div>
                </Tooltip>
            );
        } else {
            return null;
        }
    };

    const cardClasses = classNames({
        "session-card": true,
        "session-card-selected": selected,
    });

    return (
        <>
            <Card
                hoverable={true}
                size="small"
                actions={buildActionButtons()}
                className={cardClasses}
                title={buildTitleArea()}
            >
                <div className="session-card-body">
                    <Tooltip title={getStatusTooltip()} placement="right">
                        {getStatusIcon()}
                    </Tooltip>
                    {buildCardDescription()}
                    {buildHoverButton()}
                </div>
            </Card>
            {selected && (
                <ReportModal
                    sessionInfo={session.getInfo()}
                    modalOpen={reportModalOpen}
                    onOpenChange={setReportModalOpen}
                    notification={notification}
                />
            )}
            {modalContextHolder}
        </>
    );
};

const exportToFile = (sessionInfo: SessionInfo, notification: NotificationInstance) => {
    getPatchContents(sessionInfo)
        .then((patch: string) => {
            const url = window.URL.createObjectURL(new Blob([patch]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `session-${sessionInfo.session_id}.patch`);
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        })
        .then(() =>
            notification.success({
                message: "Export complete",
                description: "Downloaded changes.",
                placement: "bottomRight",
                duration: 5,
            })
        )
        .catch((err: unknown) => {
            if (err instanceof Error) console.error(err.message);

            notification.error({
                message: "Export failed",
                description: (
                    <TraceableNotificationDescription
                        description="Could not download changes."
                        session_id={sessionInfo.session_id}
                    />
                ),
                placement: "bottomRight",
            });
        });
};

const exportToClipboard = async (
    sessionInfo: SessionInfo,
    notification: NotificationInstance,
    modal: Omit<ModalStaticFunctions, "warn">
) => {
    try {
        const patch = await getPatchContents(sessionInfo);

        const onCopyPatchFromModal = async () => {
            try {
                await navigator.clipboard.writeText(patch);

                popDidCopyPatch(notification);
            } catch {
                popCopyFailed(notification);
            }

            copyPatchModal.destroy();
        };

        const copyPatchModal = modal.info({
            title: null,
            content: (
                <div>
                    <div className="copy-patch-modal-copy">
                        <Typography.Text>Click to copy changes to clipboard</Typography.Text>
                        <Button icon={<CopyOutlined />} onClick={onCopyPatchFromModal}>
                            Copy
                        </Button>
                    </div>
                    <Markdown
                        components={{
                            pre: ({ className, children, ...props }) => {
                                return (
                                    <pre
                                        className={`${className ? className : ""} copy-patch-modal-instructions-pre`}
                                        {...props}
                                    >
                                        {children}
                                    </pre>
                                );
                            },
                        }}
                    >
                        {COPY_PATCH_INSTRUCTIONS_MARKDOWN}
                    </Markdown>
                </div>
            ),
            width: 600,
            closable: true,
            icon: null,
            okButtonProps: { style: { display: "none" } },
            maskClosable: true,
            onCancel: () => {
                copyPatchModal.destroy();
            },
        });
    } catch (err: unknown) {
        if (err instanceof Error) console.error(err.message);

        popFailedToFetchPatch(sessionInfo.session_id, notification);
    }
};

const COPY_PATCH_INSTRUCTIONS_MARKDOWN = `
To apply the patch, from the root directory of the repository, run:
\`\`\`
# Mac
pbpaste | patch -p1
# Linux
xclip -o -selection clipboard | patch -p1
# Windows in Git Bash
cat /dev/clipboard | patch -p1
# Windows in WSL
powershell.exe Get-Clipboard | patch -p1
\`\`\`
`;

const popDidCopyPatch = (notification: NotificationInstance) => {
    notification.success({
        message: "Export complete",
        description: "Copied changes to clipboard.",
        placement: "bottomRight",
        duration: 5,
    });
};

const popFailedToFetchPatch = (session_id: string, notification: NotificationInstance) => {
    notification.error({
        message: "Export failed",
        description: (
            <TraceableNotificationDescription description="Failed to fetch session changes." session_id={session_id} />
        ),
        placement: "bottomRight",
    });
};

const popCopyFailed = (notification: NotificationInstance) => {
    notification.error({
        message: "Copy failed",
        description: "Could not copy changes to clipboard.",
        placement: "bottomRight",
        duration: 5,
    });
};

const syncWithRemote = (
    session: Session,
    notification: NotificationInstance,
    modal: Omit<ModalStaticFunctions, "warn">
) => {
    const syncingModal = modal.info({
        title: "Syncing with remote...",
        content: (
            <div className="create-pr-modal-loading-icon">
                <LoadingOutlined />
            </div>
        ),
        okButtonProps: { style: { display: "none" } },
        maskClosable: true,
        onCancel: () => {
            syncingModal.destroy();
        },
    });

    solverInterfaceApiAxios
        .post(`/sessions/${session.getInfo().org}/${session.getInfo().repo}/${session.getInfo().session_id}/sync`)
        .then((response) => {
            syncingModal.destroy();
            const { pulled_updates, pushed_updates } = response.data;

            if (!pulled_updates && !pushed_updates) {
                notification.success({
                    message: "No changes",
                    description: "Everything up to date",
                    placement: "bottomRight",
                });
            } else {
                const descriptions: string[] = [];
                if (pulled_updates) {
                    descriptions.push("Successfully pulled changes from remote");
                } else {
                    descriptions.push("Session already up to date");
                }
                if (pushed_updates) {
                    descriptions.push("Successfully pushed changes to remote");
                } else {
                    descriptions.push("Everything on remote up-to-date");
                }

                notification.success({
                    message: `Changes synced to ${session.remote_branch_name}`,
                    description: descriptions.join(". "),
                    placement: "bottomRight",
                });
            }
        })
        .catch((error) => {
            syncingModal.destroy();
            notification.error({
                message: "Failed to sync changes",
                description: (
                    <TraceableNotificationDescription
                        description={error.response?.data?.error || "Unknown error occurred"}
                        session_id={session.session_id}
                    />
                ),
                placement: "bottomRight",
            });
        });
};

const notifyPullRequestCreation = async (
    session: Session,
    notification: NotificationInstance,
    modal: Omit<ModalStaticFunctions, "warn">
) => {
    if (session.pull_request) {
        const prNumber = parseInt(session.pull_request);

        if (isNaN(prNumber)) {
            notification.error({
                message: "Failed to open PR",
                description: (
                    <TraceableNotificationDescription
                        description="PR number is set for this session but is not a number."
                        session_id={session.session_id}
                    />
                ),
                placement: "bottomRight",
            });
            return;
        }

        window.open(pullRequestUrl(session.auth_type, session.repo_name, prNumber), "_blank");
        return;
    }

    const creatingPRModal = modal.info({
        title: `Pushing changes to ${authTypeDisplayName(session.auth_type)}...`,
        content: (
            <div className="create-pr-modal-loading-icon">
                <LoadingOutlined />
            </div>
        ),
        okButtonProps: { style: { display: "none" } },
        maskClosable: true,
        onCancel: () => {
            creatingPRModal.destroy();
        },
    });

    try {
        const createPullRequestResponse = await createPullRequest(session.getInfo());

        switch (createPullRequestResponse.result_code) {
            case CreatePullRequestResultCode.NO_CONTENT:
                popCreatePullRequestNoContent(notification);
                break;
            case CreatePullRequestResultCode.SUCCESS:
                const createdPRModal = modal.success({
                    title: (
                        <div>
                            Pushed new branch{" "}
                            <a
                                className="create-pr-modal-link"
                                href={branchUrl(
                                    session.auth_type,
                                    session.repo_name,
                                    createPullRequestResponse.remote_branch_name
                                )}
                                target="_blank"
                                rel="noreferred noopener"
                            >
                                {createPullRequestResponse.remote_branch_name}
                            </a>{" "}
                            to {authTypeDisplayName(session.auth_type)}
                        </div>
                    ),
                    content: (
                        <a
                            className="create-pr-modal-link"
                            href={createPullRequestResponse.pull_request}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            Click here to open a PR on {authTypeDisplayName(session.auth_type)}
                        </a>
                    ),
                    closable: true,
                    okButtonProps: { style: { display: "none" } },
                    maskClosable: false,
                    onCancel: () => {
                        createdPRModal.destroy();
                    },
                });
                break;
            case CreatePullRequestResultCode.PR_CREATION_FAILED:
            default:
                popCreatePullRequestError(session.session_id, notification, createPullRequestResponse.error_message);
        }
    } catch (error) {
        const errorString: string | undefined = isAxiosError(error) ? error.response?.data?.message : undefined;

        popCreatePullRequestError(session.session_id, notification, errorString);
    } finally {
        creatingPRModal.destroy();
    }
};

const popCreatePullRequestNoContent = (notification: NotificationInstance) => {
    notification.info({
        message: "No changes to submit",
        description: "No changes to submit in this session.",
        placement: "bottomRight",
    });
};

const popCreatePullRequestError = (session_id: string, notification: NotificationInstance, errorMessage?: string) => {
    notification.error({
        message: "PR Creation Failed",
        description: (
            <TraceableNotificationDescription
                description={errorMessage || "Could not open pull request."}
                session_id={session_id}
            />
        ),
        placement: "bottomRight",
    });
};

export default SessionCard;
