"use client";
import React, { useEffect, useState } from "react";
import "./Settings.css";

import { CheckOutlined, EditOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Form, Input, Tooltip, Typography } from "antd";

import { DockerExecutionSettings, useExecutionSettingsContext } from "../data/ExecutionSettings";

interface ExecutionSettingsFormProps {
    org: string;
    repo: string;
    allowChanges: boolean;
}

interface ExecutionSettingsFormValues {
    docker_registry_url: string;
    docker_image: string;
    docker_tag: string;
    repo_mount_point?: string;
    docker_username: string;
    docker_password: string;
}

const formValuesFromExecutionSettings = (settings: DockerExecutionSettings): ExecutionSettingsFormValues => {
    return {
        docker_registry_url: settings.docker_registry_url || "",
        docker_image: settings.docker_image || "",
        docker_tag: settings.docker_tag || "",
        repo_mount_point: settings.repo_mount_point || "",
        docker_username: settings.docker_username || "",
        docker_password: settings.docker_password || "",
    };
};

const ExecutionSettingsForm: React.FC<ExecutionSettingsFormProps> = ({ org, repo, allowChanges }) => {
    const { dockerExecutionSettings, setExecutionSettings, resetExecutionSettings, error, loading } =
        useExecutionSettingsContext();

    const [editingKeys, setEditingKeys] = useState<Set<string>>(new Set<string>());
    const [submittingNewSettings, setSubmittingNewSettings] = useState<boolean>(false);
    const [deletingSettings, setDeletingSettings] = useState<boolean>(false);

    const [form] = Form.useForm<ExecutionSettingsFormValues>();

    const settingsAreNew = !dockerExecutionSettings || Object.keys(dockerExecutionSettings).length === 0;

    const onBeginEdit = (key: string) => {
        toggleEditing(key);
    };

    const onFinishEdit = (key: string) => {
        const existingValues = dockerExecutionSettings as { [key: string]: string };
        if (form.getFieldValue(key) !== existingValues[key]) {
            setExecutionSettings({
                [key]: form.getFieldValue(key),
            });
        }

        toggleEditing(key);
    };

    const toggleEditing = (key: string) => {
        setEditingKeys((prev) => {
            const newSet = new Set(prev);
            if (newSet.has(key)) {
                newSet.delete(key);
            } else {
                newSet.add(key);
            }
            return newSet;
        });
    };

    const deleteSettings = async () => {
        setDeletingSettings(true);
        await resetExecutionSettings();
        setDeletingSettings(false);
    };

    useEffect(() => {
        if (dockerExecutionSettings) {
            const formValues = formValuesFromExecutionSettings(dockerExecutionSettings);
            form.setFieldsValue(formValues);
        } else {
            form.setFieldsValue({
                docker_registry_url: "",
                docker_image: "",
                docker_tag: "",
                repo_mount_point: "",
                docker_username: "",
                docker_password: "",
            });
        }
    }, [dockerExecutionSettings, form]);

    const onFinishForm = (values: ExecutionSettingsFormValues) => {
        setSubmittingNewSettings(true);
        setExecutionSettings({
            docker_registry_url: values.docker_registry_url,
            docker_image: values.docker_image,
            docker_tag: values.docker_tag,
            repo_mount_point: values.repo_mount_point,
            docker_username: values.docker_username,
            docker_password: values.docker_password,
        });
        setSubmittingNewSettings(false);
        setEditingKeys(new Set<string>());
    };

    const buildForm = () => {
        return (
            <Form
                className="execution-settings-form"
                disabled={loading || !allowChanges}
                form={form}
                labelCol={{
                    span: 8,
                }}
                labelAlign="left"
                colon={false}
                requiredMark={false}
                onFinish={onFinishForm}
                autoComplete="off"
            >
                {buildFormItem(
                    "docker_registry_url",
                    "Registry URL",
                    false,
                    false,
                    false,
                    "The registry where the image is hosted. Example: docker.io. Can be left blank if the image is hosted on Docker Hub."
                )}
                {buildFormItem(
                    "docker_image",
                    "Image",
                    false,
                    settingsAreNew || editingKeys.has("docker_image"),
                    true,
                    "The name of the image to use for execution."
                )}
                {buildFormItem(
                    "docker_tag",
                    "Tag",
                    false,
                    false,
                    true,
                    "A tag of the image. If not provided, the latest tag will be used."
                )}
                {buildFormItem(
                    "repo_mount_point",
                    "Repo Mount Point",
                    false,
                    false,
                    true,
                    "The path to the repo within the docker image. If not provided, /repo will be used."
                )}
                {settingsAreNew &&
                    buildFormItem(
                        "docker_username",
                        "Username",
                        false,
                        settingsAreNew || editingKeys.has("docker_username"),
                        false
                    )}
                {settingsAreNew &&
                    buildFormItem(
                        "docker_password",
                        "Access Token",
                        true,
                        settingsAreNew || editingKeys.has("docker_password"),
                        false,
                        "The access token to use for authentication. Consult the host's documentation for information on how to generate an access token."
                    )}
                {settingsAreNew && (
                    <Form.Item style={{ textAlign: "center", marginTop: 24 }}>
                        <Button type="primary" htmlType="submit" loading={submittingNewSettings} block>
                            Update Docker Image
                        </Button>
                    </Form.Item>
                )}
            </Form>
        );
    };

    const buildFormItem = (
        key: string,
        label: string,
        password: boolean,
        required: boolean,
        editable: boolean,
        tooltip?: string
    ) => {
        const inputAddon = !settingsAreNew && editable && allowChanges ? buildInputAddon(key) : null;

        const inputDisabled = !allowChanges || (!settingsAreNew && !editingKeys.has(key));

        return (
            <Form.Item
                className="execution-settings-form-item"
                label={label}
                name={key}
                tooltip={tooltip}
                rules={[
                    {
                        required,
                        message: `${label} is required`,
                    },
                ]}
            >
                {password ? (
                    <Input.Password disabled={inputDisabled} addonAfter={inputAddon} />
                ) : (
                    <Input disabled={inputDisabled} addonAfter={inputAddon} />
                )}
            </Form.Item>
        );
    };

    const buildInputAddon = (key: string) => {
        if (editingKeys.has(key)) {
            return <CheckOutlined onClick={() => onFinishEdit(key)} />;
        } else {
            return <EditOutlined onClick={() => onBeginEdit(key)} />;
        }
    };

    if (loading) {
        return (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <LoadingOutlined />
            </div>
        );
    }

    return (
        <>
            <p className="settings-description">
                Configuring a custom Docker image enables Solver to run and test your code with your project's exact
                requirements and dependencies. Currently, Python is supported.
            </p>
            {!allowChanges && (
                <p className="settings-instructions">
                    Execution settings are locked for {org}/{repo}. Contact the repository owner to make changes.
                </p>
            )}
            {error && <Typography.Text type="danger">{error}</Typography.Text>}
            {buildForm()}
            {dockerExecutionSettings && allowChanges && (
                <Tooltip title="">
                    <Button onClick={deleteSettings} loading={deletingSettings}>
                        Delete execution settings
                    </Button>
                </Tooltip>
            )}
        </>
    );
};

export default ExecutionSettingsForm;
