"use client";
import {
    CaretDownOutlined,
    LoadingOutlined,
    PlusCircleOutlined,
    QuestionCircleOutlined,
    SettingOutlined,
} from "@ant-design/icons";
import { Button, Col, Divider, Dropdown, Row, Tooltip } from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { NavigationBehavior } from "../data/Navigation";
import { Repo } from "../data/Repos";
import { useSolverInterfaceContext } from "../data/SolverInterface";
import { useLoadSession } from "../data/SolverSession";
import { AuthType, vcsProviderRepoConfigUrl } from "../data/User";
import FAQModal from "./FAQModal";
import RepoCard from "./RepoCard";
import SettingsPopover from "./SettingsPopover";
import SolverLogo from "./SolverLogo";

const LOCAL_STORAGE_KEY = "shownFAQ";
interface SolverHeaderProps {
    onFaqClose: () => void;
}

const SolverHeader: React.FC<SolverHeaderProps> = ({ onFaqClose }) => {
    const loadSession = useLoadSession();
    const {
        currentUser,
        loggedIn,
        repos,
        activeRepo,
        loadingRepos,
        loadingAdditionalRepos,
        setActiveRepo,
        userNotAllowListed,
    } = useSolverInterfaceContext();

    const [settingsOpen, setSettingsOpen] = React.useState<boolean>(false);
    const [faqOpen, setFaqOpen] = useState<boolean>(false);
    const [repoDropdownTab, setRepoDropdownTab] = useState<"user" | "demo">("user");

    useEffect(() => {
        const hasLoggedInBefore = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (loggedIn && !hasLoggedInBefore) {
            setFaqOpen(true);
            localStorage.setItem(LOCAL_STORAGE_KEY, "true");
        }
    }, [loggedIn]);

    const setActiveRepoAndNavigate = (repo: Repo) => {
        if (repo.full_name === activeRepo?.full_name) {
            return;
        }

        setActiveRepo(repo, NavigationBehavior.PUSH);
        loadSession(undefined, NavigationBehavior.NONE);
    };

    const buildHeaderActions = () => {
        return (
            <Row wrap={false} align="middle" className="header-action-bar">
                {loggedIn && (
                    <Tooltip title="FAQ" arrow={false}>
                        <Button
                            type="text"
                            icon={<QuestionCircleOutlined />}
                            onClick={() => setFaqOpen(true)}
                            id="tour-faq-button"
                        />
                    </Tooltip>
                )}
                {loggedIn && buildDropdownButton()}
                {(loggedIn || userNotAllowListed) && buildSettingsButton()}
            </Row>
        );
    };

    const buildMenuItems = () => {
        if (loadingAdditionalRepos) {
            return [
                {
                    icon: (
                        <div className="repo-dropdown-non-selectable-label">
                            <LoadingOutlined />
                            Loading repos...
                        </div>
                    ),
                    key: "loading",
                },
            ];
        }

        const groupedRepos: { [org: string]: typeof repos } = {};
        repos.forEach((repo) => {
            if (repo.is_demo) return;

            if (!groupedRepos[repo.org]) {
                groupedRepos[repo.org] = [];
            }
            groupedRepos[repo.org].push(repo);
        });

        const demoRepos = repos.filter((repo) => repo.is_demo);

        const menuItems: ItemType[] = [];

        if (demoRepos.length > 0) {
            const myReposButtonClass = classNames({
                "repo-dropdown-tab-button": true,
                "repo-dropdown-tab-button-active": repoDropdownTab === "user",
            });

            const demoButtonClass = classNames({
                "repo-dropdown-tab-button": true,
                "repo-dropdown-tab-button-active": repoDropdownTab === "demo",
            });

            menuItems.push({
                icon: (
                    <div className="repo-dropdown-tabs">
                        <Button
                            className={myReposButtonClass}
                            onClick={(e) => {
                                setRepoDropdownTab("user");
                                e.stopPropagation();
                            }}
                        >
                            My Repos
                        </Button>
                        <Divider type="vertical" className="repo-dropdown-tabs-divider" />
                        <Button
                            className={demoButtonClass}
                            onClick={(e) => {
                                setRepoDropdownTab("demo");
                                e.stopPropagation();
                            }}
                        >
                            Demo Repos
                        </Button>
                    </div>
                ),
                key: "repo-type",
                onClick: () => {},
            });
        }

        if (repoDropdownTab === "demo") {
            if (demoRepos.length === 0) {
                menuItems.push({
                    icon: <div className="repo-dropdown-non-selectable-label">No demo repos found</div>,
                    key: "no-demo-repos",
                });
            } else {
                demoRepos.forEach((repo) => {
                    menuItems.push({
                        label: repo.name,
                        key: `demo-${repo.name}`,
                        onClick: () => {
                            setActiveRepoAndNavigate(repo);
                        },
                    });
                });
            }
        } else {
            if (Object.keys(groupedRepos).length === 0) {
                menuItems.push({
                    icon: <div className="repo-dropdown-non-selectable-label">No repos found</div>,
                    key: "no-user-repos",
                });
            } else {
                Object.entries(groupedRepos).forEach(([org, orgRepos], orgIndex) => {
                    if (orgIndex > 0) {
                        menuItems.push({ type: "divider" });
                    }

                    menuItems.push({
                        type: "group",
                        label: org,
                        children: orgRepos.map((repo, index) => ({
                            label: repo.name,
                            key: `${org}-${index}`,
                            onClick: () => {
                                setActiveRepoAndNavigate(repo);
                            },
                        })),
                    });
                });
            }

            if (currentUser?.auth_type === AuthType.GitHub) {
                if (menuItems.length > 0) {
                    menuItems.push({ type: "divider" });
                }

                menuItems.push({
                    icon: (
                        <a
                            className="repo-dropdown-add-repo-label"
                            href={vcsProviderRepoConfigUrl(currentUser.auth_type)}
                        >
                            <PlusCircleOutlined />
                            Add a repo
                        </a>
                    ),
                    key: "add-repo",
                });
            }
        }

        return menuItems;
    };

    const buildDropdownButton = () => {
        const menuItems = buildMenuItems();

        return (
            <div id="tour-repo-select">
                <Dropdown
                    disabled={!loggedIn || loadingRepos}
                    menu={{
                        items: menuItems,
                    }}
                    // TODO: Small visual issue: LAR-854
                    // Scrolling to the bottom removes the top border of the dropdown
                    // and scrolling to the top removes the bottom border.
                    overlayClassName="repo-dropdown-overlay scrollbar scrollbar-gutter-stable"
                    placement="bottomLeft"
                    className="repo-dropdown"
                    trigger={["click"]}
                >
                    <div>
                        {dropdownButtonContent()}
                        {menuItems.length > 0 && <CaretDownOutlined className="repo-dropdown-caret" />}
                    </div>
                </Dropdown>
            </div>
        );
    };

    const dropdownButtonContent = () => {
        if (!loggedIn) {
            return undefined;
        } else if (loadingRepos) {
            return <LoadingOutlined className="loading-repos-icon" />;
        } else if (activeRepo) {
            return <RepoCard repo={activeRepo} />;
        } else if (repos.length === 0) {
            return "No Repos Found";
        }
    };
    const buildSettingsButton = () => {
        return (
            <div id="tour-settings-button">
                <SettingsPopover popoverOpen={settingsOpen} onOpenChange={setSettingsOpen}>
                    <Button className="settings-btn" onClick={() => setSettingsOpen(!settingsOpen)}>
                        <SettingOutlined />
                    </Button>
                </SettingsPopover>
            </div>
        );
    };

    return (
        <>
            <Row wrap={false} align="middle" justify="space-between" className="session-header">
                <Col>
                    <SolverLogo onClick={() => loadSession(undefined, NavigationBehavior.PUSH)} />
                </Col>
                <Col>{buildHeaderActions()}</Col>
            </Row>

            <FAQModal
                open={faqOpen}
                onClose={() => {
                    setFaqOpen(false);
                    onFaqClose();
                }}
            />
        </>
    );
};

export default SolverHeader;
