import { notification } from "antd";
import { AxiosError } from "axios";
import qs from "qs";
import React, { useCallback, useEffect, useState } from "react";
import { expandFromRawCode } from "react-diff-view";
import { createContext, useContextSelector } from "use-context-selector";

import TraceableNotificationDescription from "../components/TraceableNotificationDescription";
import { changeSetToFileInfos, FileInfo, getRelevantPath } from "../components/Utils";
import { navigateToSession, NavigationBehavior } from "./Navigation";
import { SessionSortAttribute, SessionSortOrder } from "./SessionBrowsing";
import { useSolverInterfaceContext } from "./SolverInterface";
import { SOLVER_INTERFACE_URL_BASE, solverInterfaceApiAxios } from "./SolverInterfaceConstants";
import {
    SolverInterfaceEvent,
    SolverInterfaceEventType,
    TurnEvent as StreamTurnEvent,
    TurnEventType,
} from "./SolverInterfaceEvent";
import {
    AgentThoughtContent,
    BisectContent,
    BlameContent,
    ChangesContent,
    CodeCoverageContent,
    DocumentationContent,
    ExecutionContent,
    LinterContent,
    OpenFileContent,
    ProfileContent,
    ProjectTreeContent,
    RelevantFilesContent,
    RemoteCommitsContent,
    SolutionReviewContent,
    SolverLogContent,
    SolvingStoppedContent,
    TextSearchContent,
    UnknownTurnEventContent,
    WorkspaceCreationProgressContent,
} from "./TurnEventContent";

import { AuthType, User } from "./User";

import { SolverInterfaceEventObserverHandle, useStreamConnection } from "../hooks/useStreamConnection";

export enum CreateSessionResultCode {
    NO_ERROR = "no_error",
    REPO_NOT_CONFIGURED = "repo_not_configured",
    BRANCH_NOT_FOUND = "branch_not_found",
    FAILED_TO_CREATE_BRANCH = "failed_to_create_branch",
}

export enum SessionStatus {
    READY = "ready",
    SOLVING = "solving",
    PENDING = "pending",
    ARCHIVED = "archived",
    SUBMITTING_CANCEL = "submitting_cancel",
    SUBMITTING_SOLVE = "submitting_solve",
}

export type SessionInfo = {
    org: string;
    repo: string;
    session_id: string;
};

export interface SessionStub {
    session_id: string;
    user_id: string;
    user_name: string;
    user_avatar_url: string;
    auth_type: AuthType;
    status: SessionStatus;
    base_revision: string;
    branch_name: string;
    remote_branch_name?: string;
    repo_name: string;
    title: string;
    description: string | undefined;
    linked_issue: string | undefined;
    pending_nl_text: string | undefined;
    is_background: boolean;
    pull_request: string | undefined;
    create_timestamp: number;
    modify_timestamp: number;
    is_read_only: boolean;
}

export interface Session extends SessionStub {
    getInfo: () => SessionInfo;
}

const sessionStubToSession = (sessionStub: SessionStub): Session => {
    const [org, repo] = sessionStub.repo_name.split("/");

    return {
        ...sessionStub,
        getInfo: () => ({
            org,
            repo,
            session_id: sessionStub.session_id,
        }),
    };
};

export type Turn = {
    id: string;
    idx: number;
    error: string | undefined;
    allow_undo?: boolean;
    nl_text: string;
    events: TurnEvent[];
};

export interface TurnEvent {
    id: string;
    idx: number;
    event_type: TurnEventType;
    complete: boolean;
    created: number;
    // TODO: Why is this strongly typed?
    content:
        | SolverLogContent
        | WorkspaceCreationProgressContent
        | AgentThoughtContent
        | DocumentationContent
        | UnknownTurnEventContent
        | ChangesContent
        | ProfileContent
        | RelevantFilesContent
        | LinterContent
        | ExecutionContent
        | CodeCoverageContent
        | SolutionReviewContent
        | SolvingStoppedContent;
}

export interface SolverLogTurnEvent extends TurnEvent {
    event_type: TurnEventType.SOLVER_LOG;
    content: SolverLogContent;
}

export interface AgentThoughtTurnEvent extends TurnEvent {
    event_type: TurnEventType.AGENT_THOUGHT;
    content: AgentThoughtContent;
}

export interface WorkspaceCreationProgressEvent extends TurnEvent {
    event_type: TurnEventType.WORKSPACE_CREATION_PROGRESS;
    content: WorkspaceCreationProgressContent;
}

export interface TurnChangesEvent extends TurnEvent {
    event_type: TurnEventType.TURN_CHANGES;
    content: ChangesContent;
}

export interface DocumentationEvent extends TurnEvent {
    event_type: TurnEventType.DOCUMENTATION;
    content: DocumentationContent;
}

export interface CodeCoverageEvent extends TurnEvent {
    event_type: TurnEventType.CODE_COVERAGE;
    content: CodeCoverageContent;
}

export interface EditEvent extends TurnEvent {
    event_type: TurnEventType.EDIT;
    content: ChangesContent;
}

export interface RevertEvent extends TurnEvent {
    event_type: TurnEventType.REVERT;
    content: ChangesContent;
}

export interface ExecutionEvent extends TurnEvent {
    event_type: TurnEventType.EXECUTION;
    content: ExecutionContent;
}

export interface ProfileEvent extends TurnEvent {
    event_type: TurnEventType.PROFILE;
    content: ProfileContent;
}

export interface LinterErrorsEvent extends TurnEvent {
    event_type: TurnEventType.LINT_ERRORS;
    content: LinterContent;
}

export interface BlameEvent extends TurnEvent {
    event_type: TurnEventType.BLAME;
    content: BlameContent;
}

export interface BisectEvent extends TurnEvent {
    event_type: TurnEventType.BISECT;
    content: BisectContent;
}

export interface RelevantFilesEvent extends TurnEvent {
    event_type: TurnEventType.RELEVANT_FILES;
    content: RelevantFilesContent;
}

export interface SolutionReviewEvent extends TurnEvent {
    event_type: TurnEventType.SOLUTION_REVIEW;
    content: SolutionReviewContent;
}

export interface OpenFileEvent extends TurnEvent {
    event_type: TurnEventType.OPEN_FILE;
    content: OpenFileContent;
}

export interface RemoteCommitsEvent extends TurnEvent {
    event_type: TurnEventType.REMOTE_COMMITS;
    content: RemoteCommitsContent;
}

export interface TextSearchEvent extends TurnEvent {
    event_type: TurnEventType.TEXT_SEARCH;
    content: TextSearchContent;
}

export interface ProjectTreeEvent extends TurnEvent {
    event_type: TurnEventType.PROJECT_TREE;
    content: ProjectTreeContent;
}

export interface SolvingStoppedEvent extends TurnEvent {
    event_type: TurnEventType.SOLVING_STOPPED;
    content: SolvingStoppedContent;
}

export enum EventChangeSetState {
    LOADING = "loading",
    FETCHED = "fetched",
    ERROR = "error",
}

export type ChangeSet = {
    changes: ChangedFile[];
    preimages: FileImage[];
    postimages: FileImage[];
    file_infos: FileInfo[];
};

export type EventChangeSet = {
    state: EventChangeSetState;
    changeSet: ChangeSet;
};

export type FileImage = {
    file_path: string;
    hash: string;
    contents: string;
};

export type ChangedFile = {
    patch: string;
    change_ids: string[];
};

export enum LoadingSessionState {
    LOADING = "loading",
    DONE = "done",
    NOT_FOUND = "not_found",
    ERROR = "error",
    CREATING_AND_SOLVING = "creating_and_solving",
}

export type SessionContextType = {
    session: Session | undefined;
    turns: Turn[];
    eventChangeSets: Map<string, EventChangeSet>;
    nlText: string;
    sessionStatus: SessionStatus;
    loadingSessionState: LoadingSessionState;
    loadSession: (sessionInfo: SessionInfo | undefined, navigationBehavior: NavigationBehavior) => Promise<void>;
    updateSessionStatus: (status: SessionStatus) => void;
    updateSession: (session: Session) => void;
    revertHunk: (turn_id: string, change_id: string) => void;
    amendTurn: (turn_id: string, nl_text: string, file_images: FileImage[]) => void;
    undoTurn: (turn_id: string) => void;
    canSolve: (prompt: string) => boolean;
    solve: (prompt: string) => Promise<void>;
    createAndSolve: (nl_text: string, org: string, repo: string, branch: string | undefined) => Promise<void>;
    cancelSolve: () => Promise<void>;
    canModifySession: () => boolean;
    canModifyPendingNL: () => boolean;
    canCancelSolve: () => boolean;
    expandEventFile: (event_id: string, file_path: string, start: number, end: number) => void;
    fetchChangeSet: (
        eventId: string,
        eventType: TurnEventType.EDIT | TurnEventType.REVERT | TurnEventType.TURN_CHANGES,
        start: string,
        end: string
    ) => void;
};

// A null object for the SessionContextType that provides a default value for
// |SessionContext|. In pracice, when React renders a SessionProvider and its
// subtree, this value will be overwritten by the |value| of the provider, but
// this is useful for avoiding null checks as a client of exported hooks.
const nullSessionContext: SessionContextType = {
    session: undefined,
    turns: [],
    eventChangeSets: new Map(),
    nlText: "",
    sessionStatus: SessionStatus.READY,
    loadingSessionState: LoadingSessionState.DONE,
    loadSession: () => Promise.resolve(),
    updateSessionStatus: () => {},
    updateSession: () => {},
    revertHunk: () => {},
    amendTurn: () => {},
    undoTurn: () => {},
    canSolve: () => false,
    solve: () => Promise.resolve(),
    createAndSolve: () => Promise.resolve(),
    cancelSolve: () => Promise.resolve(),
    canModifySession: () => false,
    canModifyPendingNL: () => false,
    canCancelSolve: () => false,
    expandEventFile: () => {},
    fetchChangeSet: () => {},
};

const SessionContext = createContext<SessionContextType>(nullSessionContext);

const SessionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [loadingSessionState, setLoadingSessionState] = useState<LoadingSessionState>(LoadingSessionState.DONE);
    const [session, setSession] = useState<Session | undefined>(undefined);
    const [turns, setTurns] = useState<Turn[]>([]);
    const [eventChangeSets, setEventChangeSets] = useState<Map<string, EventChangeSet>>(new Map());
    const [nlText, setNLText] = useState<string>("");
    const [sessionStatus, setSessionStatus] = useState<SessionStatus>(SessionStatus.READY);
    const [notificationKeys, setNotificationKeys] = useState<string[]>([]);
    const [api, contextHolder] = notification.useNotification();

    const { currentUser, onStreamConnectionErrorResponse, onStreamReconnectionFailed } = useSolverInterfaceContext();

    const { connect, addSolverInterfaceEventObserver, removeSolverInterfaceEventObserver } = useStreamConnection(
        (status: number) => {
            if (status === 401 || status === 403) {
                onStreamConnectionErrorResponse(status);
            } else {
                api.error({
                    message: "Error connecting to session stream",
                    placement: "bottomRight",
                    key: "session-stream-error",
                });
            }
        },
        onStreamReconnectionFailed
    );

    useEffect(() => {
        const handle: SolverInterfaceEventObserverHandle = addSolverInterfaceEventObserver(
            SolverInterfaceEventType.TURN_EVENT,
            (solverInterfaceEvent: SolverInterfaceEvent) => {
                const turnEvent = solverInterfaceEvent as StreamTurnEvent;

                if (turnEvent.session_id !== session?.session_id) return;

                addOrUpdateTurnEvent(turnEvent);
            }
        );

        return () => removeSolverInterfaceEventObserver(handle);
    }, [session?.session_id]);

    const addOrUpdateTurnEvent = (event: StreamTurnEvent) => {
        if (!session) {
            console.log("addOrUpdateTurnEvent called with no session loaded");
            return;
        }

        const newTurnEvent: TurnEvent = {
            id: event.event_id,
            idx: event.event_idx,
            event_type: event.event_type,
            complete: event.complete,
            created: Date.now() / 1000,
            content: event.content,
        };

        if (
            newTurnEvent.event_type === TurnEventType.TURN_CHANGES ||
            newTurnEvent.event_type === TurnEventType.EDIT ||
            newTurnEvent.event_type === TurnEventType.REVERT
        ) {
            const codeEvent = newTurnEvent as TurnChangesEvent | EditEvent | RevertEvent;
            fetchChangeSet(newTurnEvent.id, newTurnEvent.event_type, codeEvent.content.start, codeEvent.content.end);
        }

        setTurns((prevTurns) => {
            return prevTurns.map((turn) => {
                if (turn.id === event.turn_id) {
                    const prevEvents = turn.events;

                    const eventsLength = prevEvents.length;
                    if (eventsLength === 0) {
                        return { ...turn, events: [newTurnEvent] };
                    } else if (prevEvents[eventsLength - 1].idx < newTurnEvent.idx) {
                        return { ...turn, events: [...prevEvents, newTurnEvent] };
                    }

                    const newEvents = prevEvents.map((turnEvent) => {
                        if (turnEvent.id === newTurnEvent.id) {
                            return newTurnEvent;
                        } else {
                            return turnEvent;
                        }
                    });

                    return { ...turn, events: newEvents };
                } else {
                    return turn;
                }
            });
        });
    };

    const loadSession = async (
        sessionInfo: SessionInfo | undefined,
        navigationBehavior: NavigationBehavior,
        createAndSolve: boolean = false
    ) => {
        notificationKeys.forEach((key) => notification.destroy(key));

        connect(undefined);

        if (!sessionInfo) {
            clearSession();
            navigateToSession(undefined, navigationBehavior);
            return;
        }

        navigateToSession(sessionInfo.session_id, navigationBehavior);

        setLoadingSessionState(createAndSolve ? LoadingSessionState.CREATING_AND_SOLVING : LoadingSessionState.LOADING);

        try {
            const [newSession, turns] = await Promise.all([getSessionBase(sessionInfo), listTurns(sessionInfo)]);

            setSession({
                ...newSession,
            });
            setTurns(turns);
            setSessionStatus(newSession.status);
            setEventChangeSets(new Map());
            setNotificationKeys([]);
            setLoadingSessionState(LoadingSessionState.DONE);

            setNLText(newSession.pending_nl_text || "");

            if (newSession.status === SessionStatus.SOLVING) {
                connect(
                    `${SOLVER_INTERFACE_URL_BASE}/api/connect/sessions/${sessionInfo.org}/${sessionInfo.repo}/${sessionInfo.session_id}`
                );
            }
        } catch (error) {
            console.log(error);
            setLoadingSessionState(LoadingSessionState.ERROR);
            return;
        }
    };

    const updateSessionStatus = (status: SessionStatus) => {
        if (!session) {
            console.log("updateSessionStatus called with no session loaded");
            return;
        }

        if (status === SessionStatus.SOLVING) {
            connect(
                `${SOLVER_INTERFACE_URL_BASE}/api/connect/sessions/${session.getInfo().org}/${session.getInfo().repo}/${
                    session.getInfo().session_id
                }`
            );
        }

        setSessionStatus(status);
    };

    const updateSession = (newSession: Session) => {
        if (!session) {
            console.log("updateSession called with no session loaded");
            return;
        }

        setSession(newSession);
    };

    const revertHunk = (turn_id: string, change_id: string) => {
        if (!session) {
            console.log("revertHunk called with no session loaded");
            return;
        }

        if (!canModifySession()) return;

        const sessionInfo = session.getInfo();

        solverInterfaceApiAxios
            .delete<Turn>(
                `/sessions/${sessionInfo.org}/${sessionInfo.repo}/${sessionInfo.session_id}/turns/${turn_id}/change/${change_id}`
            )
            .then((response) => {
                // If the response is 204, then the turn was deleted as a result of deleting the change.
                const turn = response.status === 204 ? undefined : (response.data as Turn);

                setTurns((prevTurns) => {
                    if (prevTurns.length === 0) {
                        console.error("revertHunk called with no turns");
                        return prevTurns;
                    }

                    if (!turn) {
                        const nlText = prevTurns[prevTurns.length - 1].nl_text;
                        setNLText(nlText);
                    }

                    const newTurns = turn ? [...prevTurns.slice(0, -1), turn] : prevTurns.slice(0, -1);

                    return newTurns;
                });
            })
            .catch((error) => {
                console.log(error);
                throw error;
            });
    };

    const amendTurn = (turn_id: string, nl_text: string, file_images: FileImage[]) => {
        if (!session) {
            console.log("amendTurn called with no session loaded");
            return;
        }

        if (!canModifySession()) return;

        const sessionInfo = session.getInfo();

        solverInterfaceApiAxios
            .patch<Turn>(
                `/sessions/${sessionInfo.org}/${sessionInfo.repo}/${sessionInfo.session_id}/turns/${turn_id}`,
                { nl_text, file_images }
            )
            .then((response) => {
                // If the response is 204, then the turn was deleted as a result of the amend.
                const turn = response.status === 204 ? undefined : (response.data as Turn);

                setTurns((prevTurns) => {
                    if (prevTurns.length === 0) {
                        console.error("amendTurn called with no turns");
                        return prevTurns;
                    }

                    if (!turn) {
                        const nlText = prevTurns[prevTurns.length - 1].nl_text;
                        setNLText(nlText);
                    }

                    const newTurns = turn ? [...prevTurns.slice(0, -1), turn] : prevTurns.slice(0, -1);

                    return newTurns;
                });
            })
            .catch((error) => {
                console.log(error);
                throw error;
            });
    };

    const undoTurn = (turn_id: string) => {
        if (!session) {
            console.log("undoTurn called with no session loaded");
            return;
        }

        if (!canModifySession()) return;

        const sessionInfo = session.getInfo();

        solverInterfaceApiAxios
            .delete<void>(`/sessions/${sessionInfo.org}/${sessionInfo.repo}/${sessionInfo.session_id}/turns/${turn_id}`)
            .then(() => {
                setTurns((prevTurns) => {
                    if (prevTurns.length === 0) {
                        console.error("undoTurn called with no turns");
                        return prevTurns;
                    }

                    const nlText = prevTurns[prevTurns.length - 1].nl_text;
                    setNLText(nlText);

                    return prevTurns.slice(0, -1);
                });
            })
            .catch((error) => {
                console.log(error);
                throw error;
            });
    };

    const canSolve = (prompt: string) => {
        // Need some NL.
        if (!prompt) {
            return false;
        } else if (!session) {
            return true;
        }

        return canModifySession();
    };

    const solve = (prompt: string) => {
        if (!session) {
            console.log("solve called with no session loaded");
            return Promise.resolve();
        }

        if (!canSolve(prompt)) {
            return Promise.resolve();
        }

        setNLText(prompt);
        setSessionStatus(SessionStatus.SUBMITTING_SOLVE);

        const sessionInfo = session.getInfo();

        return solverInterfaceApiAxios
            .post<Turn>(`/sessions/${sessionInfo.org}/${sessionInfo.repo}/${sessionInfo.session_id}/solve`, {
                nl_text: prompt,
            })
            .then((turn) => {
                setTurns((prevTurns) => [...prevTurns, turn.data]);
                setNLText("");
            })
            .catch((error: AxiosError) => {
                popToastForSolveError(error.response?.status || 500, sessionInfo.session_id);
                setSessionStatus(SessionStatus.READY);
            });
    };

    const createAndSolve = async (nl_text: string, org: string, repo: string, branch: string | undefined) => {
        if (session) {
            console.log("Create and solve called with a session loaded");
            return Promise.resolve();
        }

        if (!canSolve(nl_text)) {
            return Promise.resolve();
        }

        setNLText(nl_text);
        setSessionStatus(SessionStatus.SUBMITTING_SOLVE);

        return solverInterfaceApiAxios
            .post<SessionStub>(`/sessions/${org}/${repo}/solve`, {
                nl_text: nl_text,
                ref: branch,
            })
            .then((response) => {
                loadSession(sessionStubToSession(response.data).getInfo(), NavigationBehavior.PUSH, true);
            })
            .catch((error) => {
                if (error.response?.status === 429) {
                    loadSession(error.response.data.session_id, NavigationBehavior.PUSH);
                }

                popToastForSolveError(error.response?.status || 500, error.response?.data?.session_id);
            });
    };

    const cancelSolve = async () => {
        if (!session) {
            console.log("cancelSolve called with no session loaded");
            return Promise.resolve();
        }

        if (!canCancelSolve()) {
            return Promise.resolve();
        }

        // TODO: can we not submit the turn since the solver interface can infer it?
        if (turns.length === 0) {
            console.error("cancelSolve called with no turns");
            return Promise.resolve();
        }
        const lastTurnId = turns[turns.length - 1].id;

        const sessionInfo = session.getInfo();

        try {
            const response = await solverInterfaceApiAxios.post(
                `/sessions/${sessionInfo.org}/${sessionInfo.repo}/${sessionInfo.session_id}/turns/${lastTurnId}/cancel`
            );
            if (response.status === 204) {
                setSessionStatus(SessionStatus.SUBMITTING_CANCEL);
            } else {
                console.log(`Unexpected response code from cancelSolve: ${response.status}`);
            }
        } catch (error: unknown) {
            if (error instanceof AxiosError && error.response) {
                api.error({
                    message: "Error cancelling solve",
                    description: (
                        <TraceableNotificationDescription
                            description="Solve could not be cancelled."
                            session_id={session?.session_id}
                        />
                    ),
                    placement: "bottomRight",
                });
            } else {
                console.log(error);
            }
        }
    };

    const canModifySession = () => {
        if (!session) return false;
        if (currentUser?.id !== session.user_id || session.is_read_only) return false;

        return sessionStatus === SessionStatus.READY;
    };

    const canModifyPendingNL = () => {
        if (!session) return false;
        if (currentUser?.id !== session.user_id || session.is_read_only) return false;

        return sessionStatus === SessionStatus.READY || sessionStatus === SessionStatus.SOLVING;
    };

    const canCancelSolve = () => {
        if (!session) return false;
        if (currentUser?.id !== session.user_id || session.is_read_only) return false;

        return sessionStatus === SessionStatus.SOLVING || sessionStatus === SessionStatus.PENDING;
    };

    const expandEventFile = (event_id: string, file_path: string, start: number, end: number) => {
        const eventChangeSet = eventChangeSets.get(event_id);
        if (!eventChangeSet) {
            console.error(`No change set found for event ${event_id}`);
            return;
        }

        eventChangeSet.changeSet.file_infos = eventChangeSet.changeSet.file_infos.map((fi) => {
            if (getRelevantPath(fi.fileData) === file_path) {
                const source = Array.isArray(fi.source) ? fi.source : (fi.source || "").split("\n");

                fi.fileData.hunks = expandFromRawCode(fi.fileData.hunks, source, start, end);
            }

            return fi;
        });

        setEventChangeSets(
            (prevEventChangeSets) =>
                new Map(
                    prevEventChangeSets.set(event_id, {
                        ...eventChangeSet,
                    })
                )
        );
    };

    const fetchChangeSet = async (
        eventId: string,
        eventType: TurnEventType.EDIT | TurnEventType.REVERT | TurnEventType.TURN_CHANGES,
        start: string,
        end: string
    ) => {
        if (!session) {
            console.log("fetchChangeSet called with no session loaded");
            return;
        }

        if (eventChangeSets.has(eventId) && eventChangeSets.get(eventId)?.state === EventChangeSetState.FETCHED) {
            return;
        }

        setEventChangeSets((prevEventChangeSets) => {
            return new Map(
                prevEventChangeSets.set(eventId, {
                    state: EventChangeSetState.LOADING,
                    changeSet: {
                        changes: [],
                        preimages: [],
                        postimages: [],
                        file_infos: [],
                    },
                })
            );
        });

        let newState: EventChangeSetState;
        let newChangeSet: ChangeSet;
        try {
            newState = EventChangeSetState.FETCHED;
            newChangeSet = await getChanges(session.getInfo(), {
                include_preimage: eventType === TurnEventType.TURN_CHANGES,
                include_postimage: eventType === TurnEventType.TURN_CHANGES,
                start,
                end,
            });
        } catch (error) {
            newState = EventChangeSetState.ERROR;
            newChangeSet = {
                changes: [],
                preimages: [],
                postimages: [],
                file_infos: [],
            };
        }

        setEventChangeSets((prevEventChangeSets) => {
            return new Map(
                prevEventChangeSets.set(eventId, {
                    state: newState,
                    changeSet: newChangeSet,
                })
            );
        });
    };

    const clearSession = () => {
        setLoadingSessionState(LoadingSessionState.DONE);
        setSession(undefined);
        setNLText("");
        setTurns([]);
        setEventChangeSets(new Map());
        setSessionStatus(SessionStatus.READY);
    };

    const popToastForSolveError = (status: number, session_id: string | undefined = undefined) => {
        if (status === 429) {
            api.error({
                message: "Solve quota exceeded",
                description: (
                    <div>
                        Hi there! We're absolutely thrilled with how much you're Solving. You've actually just run into
                        the first usage limit we have for new users. Someone from the Solver team will be reaching out
                        shortly to discuss and get your quota raised. And if you'd like, please feel free to reach out
                        directly to us at <a href="mailto:earlyaccess@sovlerai.com">earlyaccess@solverai.com</a>.
                    </div>
                ),
                placement: "bottomRight",
                duration: null,
                key: "solve-quota-exceeded",
            });
        } else {
            api.error({
                message: "Error solving",
                description: (
                    <TraceableNotificationDescription
                        description="An error occurred while solving."
                        session_id={session_id}
                    />
                ),
                placement: "bottomRight",
            });
        }
    };

    const canModifySessionDependencies = [session, sessionStatus, currentUser];

    const value = {
        session,
        turns,
        eventChangeSets,
        nlText,
        sessionStatus: sessionStatus,
        loadingSessionState: loadingSessionState,
        loadSession: useCallback(
            (sessionInfo: SessionInfo | undefined, navigationBehavior: NavigationBehavior) =>
                loadSession(sessionInfo, navigationBehavior),
            [session?.session_id]
        ),
        updateSessionStatus: useCallback((status: SessionStatus) => updateSessionStatus(status), [session]),
        updateSession: useCallback((session: Session) => updateSession(session), [session]),
        revertHunk: useCallback(
            (turn_id: string, change_id: string) => revertHunk(turn_id, change_id),
            [turns, ...canModifySessionDependencies]
        ),
        amendTurn: useCallback(
            (turn_id: string, nl_text: string, file_images: FileImage[]) => amendTurn(turn_id, nl_text, file_images),
            [turns, ...canModifySessionDependencies]
        ),
        undoTurn: useCallback((turn_id: string) => undoTurn(turn_id), [turns, ...canModifySessionDependencies]),
        canSolve: useCallback((prompt: string) => canSolve(prompt), [turns.length, ...canModifySessionDependencies]),
        solve: useCallback((prompt: string) => solve(prompt), [turns.length, ...canModifySessionDependencies]),
        createAndSolve: useCallback(
            (nl_text: string, org: string, repo: string, branch: string | undefined) =>
                createAndSolve(nl_text, org, repo, branch),
            canModifySessionDependencies
        ),
        cancelSolve: useCallback(() => cancelSolve(), [turns, ...canModifySessionDependencies]),
        canModifySession: useCallback(() => canModifySession(), canModifySessionDependencies),
        canModifyPendingNL: useCallback(() => canModifyPendingNL(), canModifySessionDependencies),
        canCancelSolve: useCallback(() => canCancelSolve(), canModifySessionDependencies),
        expandEventFile: useCallback(
            (event_id: string, file_path: string, start: number, end: number) =>
                expandEventFile(event_id, file_path, start, end),
            [eventChangeSets]
        ),
        fetchChangeSet: useCallback(
            (
                eventId: string,
                eventType: TurnEventType.EDIT | TurnEventType.REVERT | TurnEventType.TURN_CHANGES,
                start: string,
                end: string
            ) => fetchChangeSet(eventId, eventType, start, end),
            [session, eventChangeSets]
        ),
    };

    return (
        <SessionContext.Provider value={value}>
            {contextHolder}
            {children}
        </SessionContext.Provider>
    );
};

const useSession = () => useContextSelector(SessionContext, (ctx) => ctx.session);
const useTurns = () => useContextSelector(SessionContext, (ctx) => ctx.turns);
const useEventChangeSets = () => useContextSelector(SessionContext, (ctx) => ctx.eventChangeSets);
const useSessionNLText = () => useContextSelector(SessionContext, (ctx) => ctx.nlText);
const useSessionStatus = () => useContextSelector(SessionContext, (ctx) => ctx.sessionStatus);
const useLoadingSessionState = () => useContextSelector(SessionContext, (ctx) => ctx.loadingSessionState);
const useLoadSession = () => useContextSelector(SessionContext, (ctx) => ctx.loadSession);
const useUpdateSessionStatus = () => useContextSelector(SessionContext, (ctx) => ctx.updateSessionStatus);
const useUpdateSession = () => useContextSelector(SessionContext, (ctx) => ctx.updateSession);
const useRevertHunk = () => useContextSelector(SessionContext, (ctx) => ctx.revertHunk);
const useAmendTurn = () => useContextSelector(SessionContext, (ctx) => ctx.amendTurn);
const useUndoTurn = () => useContextSelector(SessionContext, (ctx) => ctx.undoTurn);
const useCanSolve = () => useContextSelector(SessionContext, (ctx) => ctx.canSolve);
const useSolve = () => useContextSelector(SessionContext, (ctx) => ctx.solve);
const useCreateAndSolve = () => useContextSelector(SessionContext, (ctx) => ctx.createAndSolve);
const useCancelSolve = () => useContextSelector(SessionContext, (ctx) => ctx.cancelSolve);
const useCanModifySession = () => useContextSelector(SessionContext, (ctx) => ctx.canModifySession);
const useCanCancelSolve = () => useContextSelector(SessionContext, (ctx) => ctx.canCancelSolve);
const useExpandEventFile = () => useContextSelector(SessionContext, (ctx) => ctx.expandEventFile);
const useFetchChangeSet = () => useContextSelector(SessionContext, (ctx) => ctx.fetchChangeSet);

const getSessionBase = async (sessionInfo: SessionInfo): Promise<Session> => {
    return solverInterfaceApiAxios
        .get<SessionStub>(`/sessions/${sessionInfo.org}/${sessionInfo.repo}/${sessionInfo.session_id}`)
        .then((response) => sessionStubToSession(response.data))
        .catch((error) => {
            console.log(error);
            throw error;
        });
};

const listTurns = async (sessionInfo: SessionInfo): Promise<Turn[]> => {
    return solverInterfaceApiAxios
        .get<Turn[]>(`/sessions/${sessionInfo.org}/${sessionInfo.repo}/${sessionInfo.session_id}/turns`)
        .then((response) => {
            const turns = response.data;

            return turns;
        })
        .catch((error) => {
            console.log(error);
            return [];
        });
};

const getTurn = async (sessionInfo: SessionInfo, turn_id: string): Promise<Turn> => {
    return solverInterfaceApiAxios
        .get<Turn>(`/sessions/${sessionInfo.org}/${sessionInfo.repo}/${sessionInfo.session_id}/turns/${turn_id}`)
        .then((response) => {
            const turn = response.data;

            return turn;
        })
        .catch((error) => {
            console.log(error);
            throw error;
        });
};

const getSessions = async (
    org: string,
    repo: string,
    title_filter: string | undefined = undefined,
    show_all: boolean = false,
    status_filters: SessionStatus[] | undefined = undefined,
    sort_attribute: SessionSortAttribute = SessionSortAttribute.LAST_MODIFIED,
    sort_order: SessionSortOrder = SessionSortOrder.DESCENDING,
    page: number,
    page_size: number
): Promise<Session[]> => {
    const query = qs.stringify(
        {
            ...(title_filter && { title_filter }),
            ...{ show_all },
            ...(status_filters && { status_filters }),
            ...(sort_attribute && { sort_attribute }),
            ...(sort_order && { sort_order }),
            page,
            page_size,
        },
        { arrayFormat: "repeat" }
    );

    return await solverInterfaceApiAxios
        .get<SessionStub[]>(`${org}/${repo}/sessions?${query}`)
        .then((response) => response.data.map((sessionStub) => sessionStubToSession(sessionStub)))
        .catch((error) => {
            console.log(error);
            return [];
        });
};

const getRepoUsers = async (org: string, repo: string): Promise<User[]> => {
    return await solverInterfaceApiAxios
        .get<User[]>(`${org}/${repo}/users`)
        .then((response) => response.data)
        .catch((error) => {
            console.log(error);
            return [];
        });
};

const createSession = async (
    org: string,
    repo: string,
    ref: string
): Promise<{
    session: Session | undefined;
    responseCode: CreateSessionResultCode;
}> => {
    return await solverInterfaceApiAxios
        .post<SessionStub>(`${org}/${repo}/sessions`, { ref })
        .then((response) => {
            return { session: sessionStubToSession(response.data), responseCode: CreateSessionResultCode.NO_ERROR };
        })
        .catch((error) => {
            const response = error.response;
            if (response && response.data.result_code) {
                return { session: undefined, responseCode: response.data.result_code as CreateSessionResultCode };
            } else {
                console.log(error);
                throw error;
            }
        });
};

const deleteSession = async (sessionInfo: SessionInfo): Promise<boolean> => {
    return solverInterfaceApiAxios
        .delete(`/sessions/${sessionInfo.org}/${sessionInfo.repo}/${sessionInfo.session_id}`)
        .then(() => true)
        .catch(() => false);
};

const cloneSession = async (sessionInfo: SessionInfo): Promise<Session> => {
    return solverInterfaceApiAxios
        .post(`/sessions/${sessionInfo.org}/${sessionInfo.repo}/${sessionInfo.session_id}/clone`)
        .then((response) => sessionStubToSession(response.data))
        .catch((error) => {
            console.log(error);
            throw error;
        });
};

const updateSessionTitle = (sessionInfo: SessionInfo, title: string): Promise<boolean> => {
    return solverInterfaceApiAxios
        .patch(`/sessions/${sessionInfo.org}/${sessionInfo.repo}/${sessionInfo.session_id}`, { title })
        .then(() => true)
        .catch((error) => {
            console.log(error);
            return false;
        });
};

const sendSessionReport = (
    sessionInfo: SessionInfo,
    description: string,
    email?: string | null,
    canContact: boolean = false
): Promise<number> => {
    return solverInterfaceApiAxios
        .post<string>(`/sessions/${sessionInfo.org}/${sessionInfo.repo}/${sessionInfo.session_id}/report`, {
            description,
            email,
            can_contact: canContact,
        })
        .then((response) => response.status)
        .catch((error) => {
            if (error.response) {
                return error.response.status;
            } else {
                console.log(error);
                return null;
            }
        });
};

interface GetChangesOptions {
    include_preimage: boolean;
    include_postimage: boolean;
    start?: string | undefined;
    end?: string | undefined;
}

const getChanges = async (sessionInfo: SessionInfo, options: GetChangesOptions): Promise<ChangeSet> => {
    const query = qs.stringify({ ...options });

    return solverInterfaceApiAxios
        .get<ChangeSet>(`/sessions/${sessionInfo.org}/${sessionInfo.repo}/${sessionInfo.session_id}/changes?${query}`)
        .then((response) => {
            const changeSet = response.data;

            return { ...changeSet, file_infos: changeSetToFileInfos(changeSet) };
        })
        .catch((error) => {
            console.log(error);
            throw error;
        });
};

enum CreatePullRequestResultCode {
    SUCCESS,
    NO_CONTENT,
    PR_CREATION_FAILED,
}

interface CreatePullRequestResponse {
    pull_request: string;
    remote_branch_name: string;
    result_code: CreatePullRequestResultCode;
    error_message?: string;
}

const createPullRequest = async (sessionInfo: SessionInfo): Promise<CreatePullRequestResponse> => {
    return solverInterfaceApiAxios
        .post(`/sessions/${sessionInfo.org}/${sessionInfo.repo}/${sessionInfo.session_id}/pr`, { description: "" })
        .then((response) => {
            if (response.status === 204) {
                return {
                    pull_request: "",
                    remote_branch_name: "",
                    result_code: CreatePullRequestResultCode.NO_CONTENT,
                };
            } else if (response.data.pull_request) {
                return {
                    pull_request: response.data.pull_request,
                    remote_branch_name: response.data.remote_branch_name,
                    result_code: CreatePullRequestResultCode.SUCCESS,
                };
            } else {
                return {
                    pull_request: "",
                    remote_branch_name: "",
                    result_code: CreatePullRequestResultCode.PR_CREATION_FAILED,
                };
            }
        })
        .catch((error) => {
            console.log(error);
            if (error.response?.data?.message) {
                return {
                    pull_request: "",
                    remote_branch_name: "",
                    result_code: CreatePullRequestResultCode.PR_CREATION_FAILED,
                    error_message: error.response.data.message,
                };
            }
            throw error;
        });
};

const getPatchContents = async (sessionInfo: SessionInfo): Promise<string> => {
    return solverInterfaceApiAxios
        .get<string>(`/sessions/${sessionInfo.org}/${sessionInfo.repo}/${sessionInfo.session_id}/patch`)
        .then((response) => response.data)
        .catch((error) => {
            console.log(error);
            throw error;
        });
};

const sessionIsLoading = (sessionStatus: SessionStatus) => {
    return !(sessionStatus === SessionStatus.READY || sessionStatus === SessionStatus.ARCHIVED);
};

export {
    cloneSession,
    createPullRequest,
    CreatePullRequestResultCode,
    createSession,
    deleteSession,
    getChanges,
    getPatchContents,
    getRepoUsers,
    getSessionBase,
    getSessions,
    sendSessionReport,
    sessionIsLoading,
    SessionProvider,
    updateSessionTitle,
    useAmendTurn,
    useCanCancelSolve,
    useCancelSolve,
    useCanModifySession,
    useCanSolve,
    useCreateAndSolve,
    useEventChangeSets,
    useExpandEventFile,
    useFetchChangeSet,
    useLoadingSessionState,
    useLoadSession,
    useUpdateSession,
    useUpdateSessionStatus,
    useRevertHunk,
    useSession,
    useSessionNLText,
    useSessionStatus,
    useSolve,
    useTurns,
    useUndoTurn,
};

export type { CreatePullRequestResponse };
